interface ITrainingMaterials{
    trainingMaterialGuid : string;
    displayName: string;
    link: string;
    typeId: any;
    sourceTypeId: any;
    uploadedDate: string;
    ingestedDate: string;
    statusId: any;
}

export interface ITrainingMaterialsState {
        trainingMaterials: ITrainingMaterials[];
        isSucess: boolean;
}

const initalTrainingMaterialsState: ITrainingMaterialsState = {
    trainingMaterials:[],
    isSucess: false
};

export { initalTrainingMaterialsState };