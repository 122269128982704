import { HttpClient } from 'common/services/http-client.service';
import { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, ResponseType } from 'axios';
import Axios from 'common/services/http-interceptor';
import fileUploadApiConfig from './file-upload.config';
import { ApiResult } from 'common/models/api-service/api-result.model';
import eventEmitter from './file-upload-emitter';
import { HTTP_HEADER_KEY } from 'common/enums';
import { FileUploadRequest } from 'common/models/file-upload.model';
import { msalInstance, protectedResources } from 'common/config/auth.config';
import { SilentRequest } from '@azure/msal-browser';

export class FileUploadService extends HttpClient {
    
    public static async uploadFile<T>(
        payload: FileUploadRequest
    ): Promise<ApiResult<any> | undefined>{
          
        const formData = new FormData();
        formData.append('file', payload.file)   
        const apiResult:ApiResult<any> = await new Promise(async (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', payload.url, true);
        const request: SilentRequest = {
            scopes: protectedResources.api.scopes
        };
        const acquireTokenResult = await msalInstance.acquireTokenSilent(request);
        if (acquireTokenResult) {
            xhr.setRequestHeader(HTTP_HEADER_KEY.Authorization, `Bearer ${acquireTokenResult.accessToken}`);
        }
        
        xhr.upload.onprogress = event => {
            const percentageCompleted = Math.floor(100 * (event.loaded / event.total));
            eventEmitter.emit(
                      "UPLOAD_PROGRESS", 
                      percentageCompleted
            );
        };
        xhr.onreadystatechange = (result) => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                const result = new ApiResult<T>(xhr?.response, xhr.status);
                resolve(result);
            }
          
          };
          xhr.send(formData);
        });
        return apiResult;
    }

    public static async removeFile<T>(
        fileGuid: string
    ): Promise<ApiResult<any> | undefined>{
        return this.invokeApi<any>(fileUploadApiConfig.removeFile(fileGuid));
    }
}
