export const APP_CONTROLLER = {
    BOTFRAMEWORK: 'BotFramework',
    CHAT:'Chat',
    CHATS:'Chats',
    USER:'User',
    DOCUMENTS:'Documents',
    APPROVED_RESPONSES:'ApprovedResponses',
    TRAINING_MATERIALS:'TrainingMaterials'
};

export const METHOD = {
    TOKENS: 'tokens',
    FEEDBACK:'feedback',
    SESSION:'session',
    LOGOUT:'logout',
    HISTORY:'history',
    REQUEST_ACCESS:'request-access',
    MESSAGE:'Message',
    SOURCES:'Sources'
};