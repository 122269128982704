
import { FC } from "react";
import "./UploadedStatus.scss";
import { TrainingMaterialStatusLabel, TrainingMaterialStausCode } from "modules/gpo/enums/uploaded-training-material.enum";
type StatusProps={
status:TrainingMaterialStausCode 
}
const UploadedStatus: FC<StatusProps> = ({status}) => {
const getStatus=()=>{ 
    switch(status){
        case TrainingMaterialStausCode.DRAFT:
            return TrainingMaterialStatusLabel.DRAFT
        case TrainingMaterialStausCode.QUEUED:
            return TrainingMaterialStatusLabel.QUEUED       
        case TrainingMaterialStausCode.INGESTED:
            return TrainingMaterialStatusLabel.INGESTED
    }
 }
    return<>
    <div className="status__conatainer">
            <span className={status===TrainingMaterialStausCode.INGESTED?"circle__green":"circle__orange"}></span>
            <div className="status__conatainer__name">{getStatus()}</div>
    </div>
    </>
}

export default UploadedStatus;