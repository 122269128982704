import { FC, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";

import { defaultUserIcon } from "assets/media";

import "./AddApprovedResponses.scss";
import { add_icon, close_btn, doc_icon } from "assets/images";
import TextArea from "common/components/custom/Textarea/TextArea";
import Input from "common/components/custom/Input/Input";
import { FileUploader } from "common/components/custom/file-uploader/file-uploader";
import { chatActions } from "modules/gpo/redux/chat";
import { ApprovedResponsesSources } from "modules/gpo/models/chat/chat-responses-sources.model";
import DotLoader from "common/components/base/DotLoader/DotLoader";
import { AddApprovedResponseRequest } from "modules/gpo/models/chat/approved-responses.model";
import CertifiedResponse from "../CertifiedResponseModal/CertifiedResponse";


type AddApprovedResponsesProps = {
questionText:string;
answerText:string;
onClose: (close:boolean) => void;
};

const AddApprovedResponses: FC<AddApprovedResponsesProps> = ({
  questionText,
  answerText,
  onClose
}: AddApprovedResponsesProps) => {
  
  const dispatch = useAppDispatch();
  const chatState = useAppSelector((state: RootState) => state.chat);
  const [sources,setSources] =  useState<Array<ApprovedResponsesSources>>([]);
  const [selectedSources,setSelectedSources] = useState<Array<string>>([]);
  const [showLoader,setShowLoader] = useState<boolean>(false);
  const [citationText,setCitationText] = useState<string>("");
  const [newQuestionText,setNewQuestionText] = useState<string>(questionText);
  const [newAnswerText,setNewAnswerText] = useState<string>(answerText);
  const [toastMessage,setToastMessage] = useState<string>("");
  const [toastStatus,setToastStatus] = useState<boolean>(false);
  const [showToast,setShowToast] = useState<boolean>(false);
  const [hasOtherSource, setHasOtherSource] = useState<boolean>(false);
  const [showCertifiedResponse,setShowCertifiedResponse] =  useState<boolean>(false);;

  useEffect(()=>{
    console.log("dispatch approved response sources");
    //dispatch(chatActions?.getApprovedResponseSources());
  },[])
  useEffect(()=>{
    if(chatState?.approvedResponseSources && chatState?.approvedResponseSources?.isSuccess){
       if( chatState?.approvedResponseSources?.sources?.length){
         setSources(chatState?.approvedResponseSources?.sources)
       }
    }
  },[chatState?.approvedResponseSources])
  const handleCheckBoxChange = (checked:boolean,value:string,sourceType:number) =>{
    const hasOthersSelected = selectedSources?.includes(sources?.filter(source => source?.sourceTypeId == 1)[0]?.sourceGuid)
    if(sourceType == 1 && checked){
      setHasOtherSource(true);
    }
    if(hasOthersSelected){
      setHasOtherSource(true);
    }
    if( sourceType == 1 && !checked){
      setHasOtherSource(false);
    }
    if( sourceType!=1 && !hasOthersSelected){
      setHasOtherSource(false);
    }
    const sourcesCopy = selectedSources;
     if(checked){
      sourcesCopy?.push(value);
     }
     else{
      const index = sourcesCopy.indexOf(value);
      if (index > -1) {
        sourcesCopy.splice(index, 1); 
      }
     }
     setSelectedSources(sourcesCopy);
  }
  const onUploadStopped = (event:any) =>{
    console.log("upload stopped");
  }
  const onUploadStarted = (event:any) =>{
    console.log("upload started");
  }
  const onAdd =() =>{
    const request:AddApprovedResponseRequest ={
      question: newQuestionText,
      response: newAnswerText
    }
    dispatch(chatActions?.addToApprovedResponse(request));
    setShowLoader(true);
  }
  const textValueChanged = (value) =>{
    console.log("value changed",value);
  }
  const onToastClose = () => {
    console.log("Toast closed");
  }
  useEffect(()=>{
    setShowLoader(false);
    if(chatState.addToApprovedResponseState){
      if(chatState?.addToApprovedResponseState?.isSuccess){
        dispatch(chatActions?.clearAddToApprovedResponse());
        onClose(false);
      }
    }
  },[chatState.addToApprovedResponseState])
  return(
    <>
    {showLoader && (
      <DotLoader fullScreen/>
    )}

    {showCertifiedResponse && (
      <CertifiedResponse 
         onAdd={onAdd}
         onClose={setShowCertifiedResponse}
        />
    )}
    
    <div className="add-approved-wrapper">
           <div className="add-approved-responses-container">
           <div className="add-approved-responses-container__header">
            <span>Mark response as accurate</span>
            <img className="close_btn" src={close_btn} onClick={(e) => onClose(false)}/>
           </div>
         <div className="add-approved-responses-container__content">
            <div className="content-question">
                <TextArea 
                name={"response-question"}
                label="Question"
                value = {newQuestionText}
                onChange={setNewQuestionText}                
                />
            </div>
            <div className="content-response">
                <TextArea 
                name={"response-answer"}
                label="Response"
                value = {newAnswerText}
                onChange={setNewAnswerText} 
                />
            </div>
            {/* <div className="content-info">
              <span>Where did you find that info</span>
              {sources?.map((source:ApprovedResponsesSources) => (
                <>
                <input type="checkbox" id={source?.sourceGuid} name={source?.sourceTypeId?.toString()} onChange={(e) => handleCheckBoxChange(e.target.checked,source?.sourceGuid,source?.sourceTypeId)}/>
                <label htmlFor={source?.sourceGuid}>
                  <span></span>{source?.sourceName}
                </label>
                </>
              ))}
              
            </div>
            { hasOtherSource && (
              <div className="content-link">
              <TextArea 
                name={"response-citation"}
                label="Where did you find that info"
                disabled={false}
                onChange={setCitationText}
                />
              </div>
            )} */}
         </div>
         <div className="add-approved-responses-container__footer">
            <button className="primary" onClick={(e) => setShowCertifiedResponse(true)}>Certify Response</button>
            <button onClick={(e) => onClose(false)}>Close</button>
        </div>
    </div>
    </div>
    </>

  );
}

  export default AddApprovedResponses;
