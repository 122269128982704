import React, { FC, useContext, useEffect, useState } from "react";
import Header from "modules/gpo/components/Header/Header";
import ChatBot from "modules/gpo/components/Chatbot/Chatbot";
import {gpoHomeContext ,defaultGPOHomeContextValue, GPOHomeContextValue} from "./GPOHomeContext/gpo.context";
import { logo } from "assets/images";
import UploadedTrainingMaterialsModal from "modules/gpo/components/UploadedTrainingMaterialsModal/UploadedTrainingMaterialsModal";
import "./GPOHome.scss";

type GPOHomeProps = {
};
const GPOHome : FC<GPOHomeProps> = ({
}: GPOHomeProps) => {
  const [gpoHomeContextValue, setGPOHomeContextValue] = useState<GPOHomeContextValue>(defaultGPOHomeContextValue);
  const showModal = gpoHomeContextValue?.showModal;
  return (
    <gpoHomeContext.Provider value={{gpoHomeContextValue:gpoHomeContextValue,setGPOHomeContextValue:setGPOHomeContextValue}}>
    <div className="gpo-chatbot-container">
      <Header
      showProfileImage={true}
      showSearch={true}
      />
      <ChatBot />
    </div>
    {showModal && (<UploadedTrainingMaterialsModal ></UploadedTrainingMaterialsModal>)}
    
    </gpoHomeContext.Provider>
  );
};

export default GPOHome;
