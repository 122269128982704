import { FC, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";

import { defaultUserIcon } from "assets/media";

import "./ResponseDetails.scss";
import { add_icon, close_btn, doc_icon } from "assets/images";
import TextArea from "common/components/custom/Textarea/TextArea";
import Input from "common/components/custom/Input/Input";
import { FileUploader } from "common/components/custom/file-uploader/file-uploader";
import { chatActions } from "modules/gpo/redux/chat";
import { ApprovedResponsesSources } from "modules/gpo/models/chat/chat-responses-sources.model";
import DotLoader from "common/components/base/DotLoader/DotLoader";
import { AddApprovedResponseRequest, ViewApprovedResponse } from "modules/gpo/models/chat/approved-responses.model";


type ResponseDetailsProps = {
approvedResponse:ViewApprovedResponse
onClose: (close:boolean) => void;
};

const ResponseDetails: FC<ResponseDetailsProps> = ({
  approvedResponse,
  onClose
}: ResponseDetailsProps) => {
  
  const dispatch = useAppDispatch();
  const chatState = useAppSelector((state: RootState) => state.chat);
  const [sources,setSources] =  useState<Array<ApprovedResponsesSources>>([]);
  const [showLoader,setShowLoader] = useState<boolean>(false);


  return(
    <>
    {showLoader && (
      <DotLoader fullScreen/>
    )}
    
    <div className="response-detail-wrapper">
           <div className="response-detail-container">
           <div className="response-detail-container__header">
            <span>Add to approved responses</span>
            <img className="close_btn" src={close_btn} onClick={(e) => onClose(false)}/>
           </div>
         <div className="response-detail-container__content">
            <div className="content-question">
                <TextArea 
                name={"response-question"}
                label="Question"
                value={approvedResponse?.question}
                disabled={true}                 />
            </div>
            <div className="content-response">
                <TextArea 
                name={"response-answer"}
                label="Response"
                value={approvedResponse?.response}
                disabled={true}
                
                />
            </div>
            {/* {approvedResponse?.sources?.length!=0 && (
            <div className="content-info">
              <span>Where did you find that info</span>
              {approvedResponse?.sources?.map((source:ApprovedResponsesSources) => (
                <>
                <input type="checkbox" id={source?.sourceGuid} defaultChecked={true} disabled/>
                <label htmlFor={source?.sourceGuid}>
                  <span></span>{source?.sourceName}
                </label>
                </>
              ))}

            </div>
            )}
            {approvedResponse?.link && (
              <div className="content-link">
              <TextArea 
                name={"response-citation"}
                label="Citation"
                disabled={true}
                value={approvedResponse?.link}
                />
            </div>
            )} */}
            
            
         </div>
         <div className="response-detail-container__footer">
            <button onClick={(e) => onClose(false)}>Close</button>
        </div>
    </div>
    </div>
    </>

  );
}

  export default ResponseDetails;
