import { ApiResult } from "common/models";
import { TrainingMaterialsService } from "./trainingMaterials.service";
import { trainingMaterialsActions } from "./trainingMaterials.slice";
import { put, takeLatest } from "redux-saga/effects";

const getTrainingMaterials = function* (action: any) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield TrainingMaterialsService.getTrainingMaterials();
        if (hasErrors) {
            yield put({
                type: trainingMaterialsActions.getTrainingMaterials,
                payload: errors
            });
        }else{
            yield put({
                type: trainingMaterialsActions.getTrainingMaterialsSuccess,
                payload: value
            });
        }
}
const getTrainingMaterialsWatch = function* () {
    yield takeLatest(trainingMaterialsActions.getTrainingMaterials, getTrainingMaterials);  
}

export{ getTrainingMaterialsWatch  };