import React, { FC, useCallback, useEffect, useRef, useState, memo, useLayoutEffect } from "react";
import { hooks } from "botframework-webchat-component";
import moment from "moment";

import {
  like,
  like_fill,
  gpo_logo,
  open_green,
  open_grey,
  open_red,
  logout,
  doc_icon,
  add_icon,
  maximize_png,
} from "assets/images";

import "./ChatHistory.scss";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { chatHistoryActions } from "modules/gpo/redux/chatHistory";
import { ChatHistoryModel, UserChatHistory } from "common/models/chat/chat-history.model";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { ChatMessages } from "common/enums/chat-messages.enum";
import HighlightText from "../HighlightText/HighlightText";
import Divider from "../Divider/Divider";
import { IDocumentPage } from "common/models/chat/reference-document.model";
import Popup from "../Popup/Popup";
import { chatActions } from "modules/gpo/redux/chat";
import ResponseRecommendationModal from "../ResponseRecommendationModal/ResponseRecommendationModal";
import ViewApprovedResponses from "../ViewApprovedResponses/ViewApprovedResponses";
import AddApprovedResponses from "../AddApprovedResponses/AddApprovedResponses";
import { ChatDocumentPageModel, ChatMessageModel, ChatResponse } from "modules/gpo/models/chat/chat-coversation.model";
import { ChatMessageType } from "modules/gpo/enums/chat-message-type.enum";
import DualMessageAttachment from "../DualMessageAttachment/DualMessageAttachment";

  type ChatHistoryProps = {
     searchText?:string;
     onViewDoc: (pageNum: ChatDocumentPageModel[], documentGuid:string,activityId:string,showFullView:boolean,docProcessedDate:string) => void;
  };
  const ChatHistory: FC<ChatHistoryProps> = ({
    searchText,
    onViewDoc
  }: ChatHistoryProps) => {

    const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
    const chatHistoryDispatch = useAppDispatch(); 
    const [chatHistoryMessages, setChatHistoryMessages] = useState<ChatMessageModel[]|undefined>([]);
    const [groupedChatHistory,setGroupedChatHistory]= useState<any>(null);
    const [showPopup,setShowPopup]= useState<string>("");
    const popupRef = useRef<HTMLDivElement>(null);
    const [showMaximize,setShowMaximize] = useState<boolean>(false);
    const [showViewedResponse,setShowViewedResponse] = useState<boolean>(false);
    const [addApprovedResponse,setAddApprovedResponse] = useState<boolean>(false);
    const [currentAnswer,setCurrentAnswer] = useState<string>("");

    useEffect(()=>{
      chatHistoryDispatch(chatHistoryActions?.getChatHistoryResults(10));
    },[])
    const handleClickOutside = (e: any) => {
      if (popupRef.current) {
        if (!popupRef.current.contains(e.target)) {
          setShowPopup("");
        }
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    },[]);
    useEffect(()=>{
      if(chatHistoryState?.chatHistoryResults){
        setChatHistoryMessages(chatHistoryState?.chatHistoryResults?.messages);
        if(chatHistoryState?.chatHistoryResults?.messages?.length){
          const historyMessages:Array<ChatResponse>=[];
          chatHistoryState?.chatHistoryResults?.messages?.forEach((message:ChatMessageModel)=>{
            historyMessages?.push(message);
            if(message?.responses?.length==2){
                const responseCopy = {
                    ...message?.responses?.filter(response=>response?.responseTypeId==2)[0],
                    trainedResponse:message?.responses?.filter(response=>response?.responseTypeId==1)[0]
                }
                historyMessages?.push(responseCopy);
            }
            else if(message?.responses?.length==1){
               historyMessages?.push(message?.responses[0]);
            }
          })
          
        const groupedArray = historyMessages?.reduce(function (obj, item:ChatResponse) {
          obj[checkDay(item.messageCreatedDate)] = obj[checkDay(item.messageCreatedDate)] || [];
          obj[checkDay(item.messageCreatedDate)].push(item);
          return obj;
        }, {});
        setGroupedChatHistory(groupedArray)
        }
        // chatHistoryState?.chatHistory?.clientChatHistories?.map((history:UserChatHistory)=>{
        //   const historyItem = JSON.parse(JSON.stringify(history));
        //   historyItem.fromHistory = true;
        //   chatHistoryDispatch(chatActions?.updateChatResults(historyItem));
        // })
        
      }
    },[chatHistoryState?.chatHistoryResults])


    const checkDay = (date): string => {
      if(moment.utc(date)?.local().isSame(moment(), "day")){
        return "Today"
      }
      else if(moment.utc(date)?.local().isSame(moment().subtract(1, 'day'), "day")){
        return "Yesterday"
      }
      else{
        return moment.utc(date)?.local().format("dddd");  
      }
    }

    return groupedChatHistory!=null ?(
      <div className="gpo-chat-history">
        {
          Object.keys(groupedChatHistory)?.map((key: keyof typeof groupedChatHistory,index) => (
          <>
          <React.Fragment key={index}>
          <Divider dividerText={key} />    
          {groupedChatHistory[key]?.map((message:ChatResponse, i) => 
          <React.Fragment key={'user-chat-history_'+message?.messageGuid}>
            {message?.messageTypeId== ChatMessageType.BotMessage && (
              <section className="bot" id={'history-'+message?.messageGuid}>
                <div>
                <header>
                  <img src={gpo_logo} />
                    <div className="name">
                      <strong>Tax Desk ChatBot</strong>
                        <span>{checkDay(message?.messageCreatedDate)} {moment.utc(message?.messageCreatedDate)?.local().format("LT")}</span>
                    </div>
                </header>
                <div className="message-section">
                   {/* < HighlightText
                      searchText={searchText}
                      displayText={message?.messageText?.replace(/\n/g, "</br>")}
                      disableTextHighlight={true}
                    /> */}
                  <>
                 
                 {/* <div className="chatContent">
                 <div className="chatContent__untrained"  id={'chat_'+message?.messageGuid}>
                    <span className="header">Untrained AI Model</span>
                     <span className="content" dangerouslySetInnerHTML={
                      {__html: message?.messageText?.replace(/\n/g, "</br>")}}></span>
                    <div className="actions">
                        <button  className="actions__add"  onClick={(event) => openAddToApprovedModal(message?.messageText)}><img src={add_icon} />Add to approved responses</button>
                        <button className="actions__view" onClick={(event) => setShowViewedResponse(!showViewedResponse)}><img src={add_icon}/>View approved responses</button>
                    </div>
                 </div>
                 <div className="chatContent__trained" id={message?.trainedResponse?'chat_'+message?.trainedResponse?.messageGuid:''}>
                    <span className="header">Trained AI Model</span>
                    {message?.trainedResponse ?(
                     <span className="content" dangerouslySetInnerHTML={
                      {__html: message?.trainedResponse?.messageText.replace(/\n/g, "</br>")}}></span>
                    ):(
                      <span className="content">Sorry, I am not able to help you right now as I have no documents in the custom knowledge base.</span>
                    )
                    }
                    <div className="actions">
                        {message?.trainedResponse?.score && message?.trainedResponse?.score>0 && (
                           <div className="actions__doc">
                           <span>Confidence: </span>
                           {message?.trainedResponse?.score > 70 ? (
                            <span className="doc-confidence excellent">Excellent</span>
                           ): message?.trainedResponse?.score >= 50 && message?.trainedResponse?.score <= 70 ?(
                            <span className="doc-confidence medium">Medium</span>
                           ):(
                            <span className="doc-confidence low">Low</span>
                           )
                           }
                           <img className="doc-button" title="View Document" src={doc_icon} onClick={() => onViewDoc(message?.trainedResponse?.documents?message?.trainedResponse?.documents[0]?.pages:[],message?.trainedResponse?.documents?message?.trainedResponse?.documents[0]?.documentGuid:'',message?.messageGuid?message?.messageGuid:'')}/>
                          </div>
                        )}
                        
                        <button disabled={message?.trainedResponse?false:true} className="actions__add" onClick={(event) => openAddToApprovedModal(message?.trainedResponse?.messageText?message?.trainedResponse?.messageText:"")}><img src={add_icon} />Add to approved responses</button>
                    </div>
                 </div>
                 <div className="chatContent__maximize">
                   <img src={maximize_png} onClick={(event) => setShowMaximize(!showMaximize)}/>
                 </div>
             </div> */}
                            <DualMessageAttachment
                              isSingleMessage={true}
                              content={message?.messageText}
                              contentType={
                                "text/plain"
                              }
                              isHistory={true}
                              list={groupedChatHistory[key]}
                              onViewDoc ={onViewDoc}
                              currentResponse={message}
                              trainedResponse={message?.trainedResponse}
                              confidence = {message?.score}
                              gpoRole={message.messageTypeId == ChatMessageType?.BotMessage ? "bot" : "user"}
                              chatID = {message.messageGuid}
                              hasHandsOff = {false}
                              showTyping = {false}
                              stopTypingOnTabSwitch = {false}
                              isWelcomeMessage = {false}
                            />
                  </>                 
                </div>
                </div>
                </section>
              )}
              {message?.messageTypeId == ChatMessageType?.ClientMessage && (
                <section className="user" id={'history-'+message?.messageGuid}>
                  <header>
                      <div className="name">
                        <strong>You</strong>
                          <span>{checkDay(message?.messageCreatedDate)} {moment.utc(message?.messageCreatedDate)?.local().format("LT")}</span>
                      </div>
                  </header>
                  <HighlightText
                    searchText={searchText}
                    displayText={message?.messageText?.replace(/\n/g, "</br>")}
                    disableTextHighlight={true}
                   />
                </section>
               )}
                {/* {message?.messageType==ChatMessageType.EVENTMESSAGE && message?.messageText==ChatMessages.AGENT_JOINED &&( 
                  <section className="new-agent" id={'history-'+message?.messageGuid}>
                      <div className="detail">
                        <ProfileImage profileId={message?.user?.guid} />
                           <span>
                            <strong>{message?.user?.displayName}</strong> joined the chat
                          </span>
                          <span>{checkDay(message?.messageCreatedDate)} {moment.utc(message?.messageCreatedDate)?.local().format("LT")}</span>
                      </div>
                 </section> 
                )}
                {message?.messageType==ChatMessageType.AGENTMESSAGE && (
                    <section className="agent" id={'history-'+message?.messageGuid}>
                      <header>
                      <ProfileImage profileId={message?.user?.guid} />
                      <div className="name">
                      <strong>{message?.user?.displayName}</strong>
                        <span>{checkDay(message?.messageCreatedDate)} {moment.utc(message?.messageCreatedDate)?.local().format("LT")}</span>
                      </div>
                      </header>
                      <div className="message-section">
                   < HighlightText
                      searchText={searchText}
                      displayText={message?.messageText?.replace(/\n/g, "</br>")}
                      disableTextHighlight={true}
                    />
                    {message?.isMessageLiked==null && (
                    <div className="user-action">
                        <button><img src={like} /></button>
                        <button><img src={like} /></button>
                    </div>
                    )}
                    {message?.isMessageLiked!=null && message?.isMessageLiked && (
                    <div className="user-action">
                        <button><img src={like_fill} /></button>
                        <button><img src={like} /></button>
                    </div>
                    )}
                    {message?.isMessageLiked!=null && !message?.isMessageLiked && (
                      <div className="user-action">
                          <button><img src={like} /></button>
                          <button><img src={like_fill} /></button>
                      </div>
                    )}
                </div>
                <div className="message-footer">
                  <div className="gpo-icon" onClick={(event) => setShowPopup(message?.messageGuid)}>
                    {message?.additionalInfo && JSON.parse(message?.additionalInfo)?.results && JSON.parse(message?.additionalInfo)?.results[0] && JSON.parse(message?.additionalInfo)?.results[0]?.score!=0  && (
                      <>
                      {JSON.parse(message?.additionalInfo)?.results[0]?.score < 50 &&(
                        <img src={open_red} />
                      )}
                      {JSON.parse(message?.additionalInfo)?.results[0]?.score >= 50 && JSON.parse(message?.additionalInfo)?.results[0]?.score <= 70 &&(
                        <img src={open_grey} />
                      )}
                      {JSON.parse(message?.additionalInfo)?.results[0]?.score > 70 &&(
                        <img src={open_green} />
                      )}
                      </>
                    )}  
                    {showPopup==message?.messageGuid && (
                   <div className="gpo-pop-up">
                  {(message?.additionalInfo &&
                  <div ref={popupRef}>
                  <Popup
                    onViewDoc={onViewDoc}
                    similarityScore={JSON.parse(message?.additionalInfo)?.results[0]?.score}
                    documents={JSON.parse(message?.additionalInfo)?.results[0]?.documents}
                    activityId={message?.messageGuid}
                   />
                   </div>
                   )}
                  </div>
                  )}              
                  </div>
                  
                </div>                 
                    </section>                     
                )}
                {message?.messageType==ChatMessageType.EVENTMESSAGE && message?.messageText==ChatMessages.AGENT_LEFT &&(  
                  <section className="agent-left" id={'history-'+message?.messageGuid}>
                    <div className="center">
                      <img src={logout} />
                        <span>
                          <strong>{message?.user?.displayName}</strong> left the chat
                        </span>
                    </div>
                  </section>
                )} */}
              </React.Fragment>
          )} 
          </React.Fragment>
          </>
          
          
        ))
        }
        {chatHistoryMessages?.length && groupedChatHistory && !groupedChatHistory["Today"] &&(
           <Divider dividerText={"Today"} />    
        )}
            
      </div>
    ):<></>;
  }

export default ChatHistory;
