import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChatFeedback, IChatFeedbackRequest, IChatFeedbackResponse } from 'common/models/chat/chat-feedback-request.model';
import { UserChatHistory } from 'common/models/chat/chat-history.model';
import { IDocumentDetails, IDocumentPage } from 'common/models/chat/reference-document.model';
import { initalChatState } from './chat.state';
import { ChatCoversationCreationState } from 'modules/gpo/models/chat/chat-coversation-creation-response.model';
import { ChatConversationModel, ChatRequestModel } from 'modules/gpo/models/chat/chat-coversation.model';
import { ApprovedResponsesSourceResponses } from 'modules/gpo/models/chat/chat-responses-sources.model';
import { AddApprovedResponseRequest, AddApprovedResponses, EditApprovedResponseRequest, ViewApprovedResponses, ViewApprovedResponsesRequest } from 'modules/gpo/models/chat/approved-responses.model';
import { initialChatHistoryState } from '../chatHistory/chatHistory.state';
import { TrainingMaterialLinkRequest } from 'common/models/training-material-link-request.model';


const chatSlice = createSlice({
    name: 'chat',
    initialState: initalChatState,
    reducers: {
        sendUserFeedback: (state,{ payload }: PayloadAction<IChatFeedbackRequest>) => {
        },
        sendUserFeedbackSuccess: (state, { payload }: PayloadAction<any>) => {
             state.userFeedbackState = payload;
        },
        sendUserFeedbackFailed: (state, { payload }: PayloadAction<any>) => {
            state.userFeedbackState = initalChatState.userFeedbackState;
            console.log("Errors",payload);
        },
        getPDFDetails: (state,{ payload }: PayloadAction<string>) => {
            
        },
        getPDFDetailsSuccess: (state, { payload }: PayloadAction<IDocumentDetails>) => {
            state.pdfDocumentDetails = payload;
        },
        getPDFDetailsFailed: (state, { payload }: PayloadAction<any>) => {
            state.pdfDocumentDetails = initalChatState.pdfDocumentDetails
            console.log("Errors",payload);
        },
        updateChatHandsOffState: (state,action: PayloadAction<{id:string,message:string}>) => {
            state.handsOffAcknowledged.push(action.payload)
        },
        updateChatDocUrlState: (state,action: PayloadAction<string>) => {
            state.viewDocURL = action.payload
        },
        updateChatDocGuidState: (state,action: PayloadAction<string>) => {
            state.viewDocGuid = action.payload
        },
        updateChatDocPageNumState: (state,action: PayloadAction<IDocumentPage[]>) => {
            state.viewDocPageNum = action.payload
        },
        updateDocOpenState: (state,action: PayloadAction<boolean>) => {
            state.isDocumentOpen = action.payload
        },
        updateChatScrollHeight:(state,action:PayloadAction<any>)=>{
            state.chatScrollHeight = action.payload
        },
        updateChatDocDateState: (state,action: PayloadAction<string>) => {
            state.viewDocProcessedDate = action.payload
        },
        updatePDFFullScreen:(state,action:PayloadAction<any>)=>{
            state.showPDFinFullView = action.payload
        },
        updateAgentLeavingAcknowledgedState: (state,action: PayloadAction<{id:string,message:string}>) => {
            state.agentLeavingAcknowledged.push(action.payload)
        },
        updateMessageInputState: (state,action: PayloadAction<boolean>) => {
            state.messageInputEnabled = action.payload
        },
        updateMessageDisplayed: (state,action: PayloadAction<string>) => {
            state.messagesDisplayed.push(action.payload)
        },
        updateChatResults : (state,action: PayloadAction<UserChatHistory>) =>{
            state.chatSearchResults.push(action?.payload)
        },
        updateChatSearchMessages : (state,action: PayloadAction<UserChatHistory[]|undefined>) =>{
            state.searchMessages = action?.payload
        },
        updateChatScrolled : (state,action: PayloadAction<boolean>) =>{
            state.chatScrolled = action?.payload
        },
        createCoversation: (state) => {
            state.conversationGuid=undefined;
        },
        createCoversationSuccess: (state, { payload }: PayloadAction<ChatCoversationCreationState>) => {
            state.conversationGuid = payload.conversationGuid;
        },
        createCoversationFailed: (state, { payload }: PayloadAction<any>) => {
            state.conversationGuid = initalChatState.conversationGuid;
            console.log("Errors",payload);
        },
        createQuery: (state, { payload }: PayloadAction<ChatRequestModel>) => {
            console.log("GetChat");
        },
        createQuerySuccess: (state, { payload }: PayloadAction<ChatConversationModel>) => {
            state.chatResponse = payload;
        },
        createQueryFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.chatResponse) {
                state.chatResponse = {
                    isSuccess : false
                }
            }
            console.log("Errors",payload);
        },
        getApprovedResponseSources: (state) => {
        },
        getApprovedResponseSourcesSuccess: (state, { payload }: PayloadAction<ApprovedResponsesSourceResponses>) => {
             state.approvedResponseSources = payload;
        },
        getApprovedResponseSourcesFailed: (state, { payload }: PayloadAction<any>) => {
            state.approvedResponseSources = initalChatState.approvedResponseSources;
            console.log("Errors",payload);
        },
        addToApprovedResponse: (state, { payload }: PayloadAction<AddApprovedResponseRequest>) => {
            console.log("addToApprovedResponse");
        },
        addToApprovedResponseSuccess: (state, { payload }: PayloadAction<AddApprovedResponses>) => {
            state.addToApprovedResponseState = payload;
        },
        addToApprovedResponseFailed: (state, { payload }: PayloadAction<any>) => {
            state.addToApprovedResponseState = initalChatState.addToApprovedResponseState;
            console.log("Errors",payload);
        },
        clearAddToApprovedResponse : (state) => {
            state.addToApprovedResponseState = initalChatState.addToApprovedResponseState;
        },
        viewApprovedResponses: (state, { payload }: PayloadAction<ViewApprovedResponsesRequest>) => {
            console.log("ViewApprovedResponsesRequest");
        },
        viewApprovedResponsesSuccess: (state, { payload }: PayloadAction<ViewApprovedResponses>) => {
            state.viewApprovedResponses = payload;
        },
        viewApprovedResponsesFailed: (state, { payload }: PayloadAction<any>) => {
            state.viewApprovedResponses = initalChatState.viewApprovedResponses;
            console.log("Errors",payload);
        },
        clearViewApprovedResponse : (state) => {
            state.viewApprovedResponses = initalChatState.viewApprovedResponses;
        },
        editApprovedResponses: (state, { payload }: PayloadAction<EditApprovedResponseRequest>) => {
            console.log("EditApprovedResponseRequest");
        },
        editApprovedResponsesSuccess: (state, { payload }: PayloadAction<any>) => {
            state.editApprovedResponse = payload;
        },
        editApprovedResponsesFailed: (state, { payload }: PayloadAction<any>) => {
            if (!state.editApprovedResponse) {
                state.editApprovedResponse = {
                    isSuccess : false
                }
            }
            console.log("Errors",payload);
        },
        clearEditApprovedResponse : (state) => {
            state.editApprovedResponse = initalChatState.editApprovedResponse;
        },
        sendChatFeedback: (state,{ payload }: PayloadAction<IChatFeedback>) => {
        },
        sendChatFeedbackSuccess: (state, { payload }: PayloadAction<IChatFeedbackResponse>) => {
             state.chatFeedbackResponse = payload;
        },
        sendChatFeedbackFailed: (state, { payload }: PayloadAction<any>) => {
            state.chatFeedbackResponse = initalChatState.userFeedbackState;
            console.log("Errors",payload);
        },
        saveTrainingMaterialLink : (state, { payload }: PayloadAction<TrainingMaterialLinkRequest>) => {
            
        },
        saveTrainingMaterialLinkSuccess : (state, { payload }: PayloadAction<any>) => {
            state.documentLinkResponse = payload;
        },
        saveTrainingMaterialLinkFailed : (state, { payload }: PayloadAction<any>) => {
            state.documentLinkResponse = initalChatState.userFeedbackState;
            console.log("Errors",payload);
        },
        clearTrainingMaterialLinkState : (state) => {
            state.documentLinkResponse = initalChatState.documentLinkResponse;
        },
        updateTrainingMaterialStatus : (state, { payload }: PayloadAction<boolean>) => {
            state.trainingMaterialUpdated = payload;
        }, 
        


    }
});

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;
