export enum TrainingMaterialTypeCode {
    Document=1,
    URL
}
export enum TrainingMaterialTypeLabel {
    Document="Document",
    URL="URL"
}
export enum TrainingMaterialDocumentType {
    NONE="None",
    PDF="PDF",
}


export enum TrainingMaterialStausCode {
    DRAFT=1,
    QUEUED,
    INGESTED
}
export enum TrainingMaterialStatusLabel {
    DRAFT="Draft",
    QUEUED="Queued",
    INGESTED="Ingested"
}



