import React, { FC, useCallback, useEffect, useRef, useState, memo, useContext } from "react";
import { hooks } from "botframework-webchat-component";
import moment from "moment";
import getValueOrUndefined from "common/utils/utility";
import Popup from "../Popup/Popup";
import Attachment from "../Attachment/Attachment";

import {
  like,
  like_fill,
  gpo_logo,
  open_green,
  open_grey,
  open_red,
  logout,
} from "assets/images";

import "./Chat.scss";
import { defaultUserIcon } from "assets/media";
import { IChatFeedbackRequest } from "common/models/chat/chat-feedback-request.model";
import { useSelector, useDispatch } from 'react-redux';
import { IDocumentPage } from "common/models/chat/reference-document.model";
import { CustomBotEventTypes } from "common/enums/chatbot-event-types.enum";
import { IChatFeedbackEvent } from "common/models/chat/chat-feedback-event.model";
import { ENV_CONFIG } from "common/config/env.config";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { chatActions } from "modules/gpo/redux/chat";
import ChatHistory from "../ChatHistory/ChatHistory";
import { AcknowledgmentType } from "common/enums/acknowledgment-type.enum";
import ConnectingLoader from "../ConnectingLoader/ConnectingLoader";
import { ChatConversationModel, ChatDocumentPageModel, ChatMessageModel, ChatRequestModel, ChatResponse } from "modules/gpo/models/chat/chat-coversation.model";
import { ChatMessageType } from "modules/gpo/enums/chat-message-type.enum";
import { act } from "@testing-library/react";
import DualMessageAttachment from "../DualMessageAttachment/DualMessageAttachment";
import Toaster from "common/components/custom/Toaster/Toaster";
import { gpoHomeContext } from "modules/gpo/pages/GPOHome/GPOHomeContext/gpo.context";

type ChatProps = {
  onViewDoc: (pageNum: ChatDocumentPageModel[], documentGuid:string,activityId:string,showFullView:boolean,docProcessedDate:string) => void;
};
const Chat: FC<ChatProps> = ({ onViewDoc }: ChatProps) => {
   const dispatch = useAppDispatch();
   const chatState = useAppSelector((state: RootState) => state.chat);
   const userState = useAppSelector((state: RootState) => state.user);
   const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
   const [connectingLoader, setConnectingLoader] = useState(true); 
   const [activities,setActivities] = useState<Array<ChatResponse>>([]);
   const welcomeMessage = "Hi there! I’m here to answer your questions on tax.";
   const messagesEndRef = useRef<HTMLDivElement>(null);
   const [stopCurrentTyping,setStopCurrentTyping] = useState(false);
   const [sendBoxValue, setSendBoxValue] = useState("");
   const [ showChatLoader,setShowChatLoader] = useState<boolean>(false);
   const [toastMessage,setToastMessage] = useState<string>("");
   const [toastStatus,setToastStatus] = useState<boolean>(false);
   const [showToast,setShowToast] = useState<boolean>(false);
   const [errorMessageCounter,setErrorMessageCounter] = useState<number>(1);
   const [currentTime, setCurrentTime] = useState(0);
   
   const { gpoHomeContextValue, setGPOHomeContextValue } =
    useContext(gpoHomeContext);
   const handleScroll = (event) =>{
    dispatch(chatActions?.updateChatScrolled(true));
   }
   useEffect(()=>{
    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState == "visible") {
        setStopCurrentTyping(false);
      } else {
        setStopCurrentTyping(true);
      }
    });
  },[])
  useEffect(() => {
    const timeInterval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);
    return () => {
      clearInterval(timeInterval);
    };
  }, []);
   useEffect(()=>{
    //  if(chatState?.conversationGuid){
    //  dispatch(chatActions?.createQuery(chatState?.conversationGuid));
    //  }
    if(!activities?.length){
      const welcomeResponse:ChatResponse={
          conversationGuid:chatState?.conversationGuid?chatState?.conversationGuid:"",
          messageGuid: "welcomeMessage",
          messageTypeId: ChatMessageType.BotMessage,
          messageText: welcomeMessage,
          user: {
              guid: userState?.userDetails?.guid,
              displayName: userState?.userDetails?.displayName,
              emailId: userState?.userDetails?.emailId
          },
          messageCreatedDate: moment.utc(new Date()).toString()
      }
      setActivities([welcomeResponse]);
    }

   },[])
   useEffect(()=>{
    if (messagesEndRef.current && activities?.length) {
        messagesEndRef.current.scrollIntoView({
          //behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
   },[activities?.length])
   useEffect(()=>{
    if(chatState?.conversationGuid && chatHistoryState?.chatHistoryResults && activities){
      if(chatHistoryState?.chatHistoryResults?.messages?.length || activities?.length){
      setConnectingLoader(false);
      }
    }
  },[chatState?.conversationGuid,chatHistoryState?.chatHistoryResults,activities])
  
  useEffect(()=>{
    setShowChatLoader(false);
     if(chatState?.chatResponse && chatState?.chatResponse?.isSuccess){
        activities?.pop();
        const activitiesCopy:Array<ChatResponse> = activities;
        if(chatState?.chatResponse?.message){
            activitiesCopy?.push(chatState?.chatResponse?.message);
            if(chatState?.chatResponse?.message?.responses?.length==2){
                const responseCopy = {
                    ...chatState?.chatResponse?.message?.responses?.filter(response=>response?.responseTypeId==2)[0],
                    trainedResponse:chatState?.chatResponse?.message?.responses?.filter(response=>response?.responseTypeId==1)[0]
                }
                activitiesCopy?.push(responseCopy);
            }
            else if(chatState?.chatResponse?.message?.responses?.length==1){
                activitiesCopy?.push(chatState?.chatResponse?.message?.responses[0]);
            }
        }
        setActivities(activitiesCopy);
     }
     else if(chatState?.chatResponse && !chatState?.chatResponse?.isSuccess){
        const errorMessage:ChatResponse={
            conversationGuid:chatState?.conversationGuid?chatState?.conversationGuid:"",
            messageGuid: "errorMessage_"+errorMessageCounter,
            messageTypeId: ChatMessageType.BotMessage,
            messageText: "Something went wrong. Please try again later!",
            user: {
                guid: userState?.userDetails?.guid,
                displayName: userState?.userDetails?.displayName,
                emailId: userState?.userDetails?.emailId
            },
            messageCreatedDate: moment.utc(new Date()).toString()
        };
        setErrorMessageCounter(errorMessageCounter+1);
        const activitiesCopy:Array<ChatResponse> = activities;
        activitiesCopy?.push(errorMessage);
        
        setActivities(activitiesCopy);
     }
  },[chatState?.chatResponse])
  const handleChange = useCallback(
    ({ target: { value } }: any) => setSendBoxValue(value),
    [setSendBoxValue]
  );
  let messageIndex=0;
  const handleSubmit = (event:any) =>{
    event.preventDefault();
    dispatch(chatActions?.updateMessageInputState(false));
    if(chatState?.conversationGuid && sendBoxValue){
        const userMessage:ChatResponse={
            conversationGuid:chatState?.conversationGuid?chatState?.conversationGuid:"",
            messageGuid: "userMessage" + messageIndex++,
            messageTypeId: ChatMessageType.ClientMessage,
            messageText: sendBoxValue,
            user: {
                guid: userState?.userDetails?.guid,
                displayName: userState?.userDetails?.displayName,
                emailId: userState?.userDetails?.emailId
            },
            messageCreatedDate: moment.utc(new Date()).toString()
        };
    const activitiesCopy:Array<ChatResponse> = activities;
    activitiesCopy?.push(userMessage);      
    setActivities(activitiesCopy);
    const chatRequest:ChatRequestModel = {
        query: {
            query: sendBoxValue
        },
        coversationGuid: chatState?.conversationGuid
    }
    dispatch(chatActions?.createQuery(chatRequest));
    setShowChatLoader(true);
    }
    setSendBoxValue("");
  };
  const onToastClose = () => {
    setShowToast(false);
  }
  useEffect(()=>{
    if(chatState.addToApprovedResponseState){
      if(chatState?.addToApprovedResponseState?.isSuccess){
        setToastMessage("Added to approved responses");
        setToastStatus(true);
        setShowToast(true);
      }
    }
  },[chatState.addToApprovedResponseState])
  useEffect(()=>{
    if(chatState.editApprovedResponse){
      if(chatState?.editApprovedResponse?.isSuccess){
        setToastMessage("Added to approved responses");
        setToastStatus(true);
        setShowToast(true);
      }
    }
  },[chatState.editApprovedResponse])
  
  useEffect(()=>{
    if(chatState.trainingMaterialUpdated){
        setToastMessage("Training material added successfully!");
        setToastStatus(true);
        setShowToast(true);
        setGPOHomeContextValue?.({ ...gpoHomeContextValue, showFolderCheckToolTip: true ,showNotification: true});
    }
  },[chatState.trainingMaterialUpdated])
  return (
    <>
              {showToast && (
               <Toaster 
                message={toastMessage} 
                isSuccess={toastStatus} 
                onCloseToast={onToastClose}/>
              )}      
    <div className="gpo-custom-bot">
      <div className="gpo-custom-chat-messages"  onWheel={(event) => handleScroll(event)}>
        <section className="gpo-chatbot-container__banner">
          <img src={gpo_logo} />
          <h1>Welcome to GRACIE</h1>
          <p className="app-name">GRACIE: <b>G</b>enerative ai <b>R</b>eturning <b>A</b>dvice, <b>C</b>ompliance, <b>I</b>nsights and <b>E</b>ducation</p>
          <p>You can ask the chatbot sample questions as below:</p>
          <ul>
            <li>
              How do you request a waiver for failing to take a RMD?
            </li>
            <li>
            	Can an individual with an Inherited IRA subject to RMDs do a QCD?
            </li>
            <li>How is a conversion to a Roth IRA taxed?</li>
          </ul>
        </section>
        {!!connectingLoader &&(
          <ConnectingLoader/>
        )}   
       
        <div className="gpo-chat-wrapper">
        <ChatHistory
          onViewDoc ={onViewDoc}
        />
         {!connectingLoader &&(
          <>
          {activities
            .map((activity: ChatResponse) => ({
              ...activity,
              MessageText:
                getValueOrUndefined(
                  activity,
                  "channelData",
                  "messageBack",
                  "displayText"
                ) || activity.messageText,
            }))
            .filter(({ MessageText }) => MessageText)
            .map((activity, index, list) => (
              <React.Fragment key={activity.messageGuid || index}>
                <div className="gpo-chat-wrapper__list">
                  <div
                    className={
                      activity.messageTypeId == ChatMessageType?.BotMessage ? "gpo-bot" : "gpo-user"
                    }
                  >
                    <div className="gpo-chats">
                    <div className="gpo-message-section">
                        {activity.messageTypeId == ChatMessageType?.BotMessage &&(
                          <div className="gpo-bot-avatar">
                            <img src={gpo_logo} />
                            <span>
                              Tax Desk ChatBot
                              <span className="gpo-time">
                                {moment(activity?.messageCreatedDate).fromNow()}
                              </span>
                            </span>
                          </div>
                        )}

                        {activity.messageTypeId == ChatMessageType?.ClientMessage && (
                          <div className="gpo-user-avatar">
                            <span>
                              You
                              <span className="gpo-time">
                                {moment(activity?.messageCreatedDate).fromNow()}
                              </span>
                            </span>
                          </div>
                        )}
                        <div className="gpo-message-section__top">
                          {!!activity.MessageText  && (
                            <DualMessageAttachment
                              isSingleMessage={true}
                              isHistory={false}
                              content={activity.MessageText}
                              contentType={
                                "text/plain"
                              }
                              list={activities}
                              onViewDoc ={onViewDoc}
                              currentResponse={activity}
                              trainedResponse={activity?.trainedResponse}
                              confidence = {activity?.score}
                              gpoRole={activity.messageTypeId == ChatMessageType?.BotMessage ? "bot" : "user"}
                              chatID = {activity.messageGuid}
                              showTyping = {activity.messageGuid=="welcomeMessage" || activity.messageGuid?.includes("errorMessage") }
                              stopTypingOnTabSwitch = {stopCurrentTyping}
                              isWelcomeMessage = {activity.messageGuid=="welcomeMessage" || activity.messageGuid.includes("errorMessage") }
                            />
                          )}
                        </div>      
                      </div>
                    </div>
                  </div>
                </div>

                {/* {list?.length - 1 === index &&
                  list[list?.length - 1]?.messageTypeId != ChatMessageType?.BotMessage  && (
                    <div className="gpo-chat-wrapper__list">
                      <div className={"gpo-bot"}>
                        <div className="gpo-chats">  
                          <div className="gpo-message-section">
                            <div className="gpo-no-data">
                              <>
                                 <div className="gpo-bot-avatar">
                                 <img src={gpo_logo} />
                                 <span>Tax Desk ChatBot</span>
                                </div>
                                <div className="gpo-loading-data">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div> 
                                </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}
              </React.Fragment>
            ))}
            </>
         )}
         {showChatLoader && (
           <div className="gpo-chat-wrapper__list">
           <div className={"gpo-bot"}>
             <div className="gpo-chats">  
               <div className="gpo-message-section">
                 <div className="gpo-no-data">
                   <>
                      <div className="gpo-bot-avatar">
                      <img src={gpo_logo} />
                      <span>Tax Desk ChatBot</span>
                     </div>
                     <div className="gpo-loading-data">
                       <div></div>
                       <div></div>
                       <div></div>
                     </div> 
                     </>
                 </div>
               </div>
             </div>
           </div>
         </div>
         )}
        
          <div ref={messagesEndRef} />
        </div>
        
      </div>
      {!connectingLoader &&(
      <div className="gpo-chat-send">
        <div className={!chatState?.messageInputEnabled?'gpo-chat-send__field disabled':'gpo-chat-send__field'}>
          <form onSubmit={sendBoxValue?handleSubmit:undefined}>
            <input
              autoFocus={true}
              disabled={!chatState?.messageInputEnabled}
              onChange={handleChange}
              type="text"
              spellCheck="true"
              value={sendBoxValue}
              placeholder="Enter your message"
            />
            <button type="submit" disabled={!chatState?.messageInputEnabled || sendBoxValue==""}>
              <svg viewBox="0 0 21 18" fill="none">
                <path
                  d="M0.00999999 18L21 9L0.00999999 0L0 7L15 9L0 11L0.00999999 18Z"
                  fill="white"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
      )}
    </div>
    </>
  );
};

export default memo(Chat);
