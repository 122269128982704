import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {retryIcon,file_icon,remove_icon, upload_icon, upload_error} from "assets/images/index"
import './file-uploader.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploadActions } from 'common/redux/file-upload';
import { RootState } from 'common/redux/core/root.reducer';
import eventEmitter from 'common/redux/file-upload/file-upload-emitter';
import DotLoader from 'common/components/base/DotLoader/DotLoader';
// import {DotLoader} from "common/components/base/DotLoader"


// let nextUniqueId:any;
// nextUniqueId = 0;

// const defaultIcons = {
//   upload: <FileIcCloudUpload24px />
// };

/**
 * @typedef Props
 * @prop  {string} [className]
 * @prop {boolean} [disabled]
 * @prop {string} [label]
 * @prop {string} [id]
 * @prop {() => void} [onDrop]
 * @prop {onUploadStarted: () => void}
 * @prop {onUploadStopped: (fileploadResponse) => void}
 * @prop {{[key:string]:[]}} [acceptableFileTypes]
 * @prop {boolean} [allowMultiple]
 * @prop {string} [uploadUrl]
 * @prop {boolean} [isRemoveFile]
 * 
 */
/**
 * @param {Props & {[x: string]: any;}} props
 */
const max_word_count = 4000;
const FileUploader = (props:any) => {
  const {
    className = '',
    disabled = false,
    label = '',
    id = '',
    onUploadStarted,
    onUploadStopped,
    isRemoveFile,   
    onFileDrop: onDropCallback = null,
    acceptableFileTypes,
    allowMultiple=true,
    uploadUrl,
    ...other
  } = props;

  const dispatch = useDispatch();
  const fileUploadState = useSelector((state: RootState) => state.fileUpload);
  const uploadedFilesState = useSelector((state: RootState) => state.fileUpload.uploadedFiles);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentLoadingFile, setCurrentLoadingFile] = useState<any>();
  const [filesToLoad, setFilesToLoad]  = useState<Array<any>>([])
  const [filesLoaded, setFilesLoaded]  = useState<Array<any>>([])
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [currentLoadedFileGuid, setCurrentLoadedFileGuid] = useState<string>();
  
  useEffect(()=>{
    if(isRemoveFile){
      console.log("isRemoveFile",isRemoveFile);
      if(uploadedFilesState){
        if(uploadedFilesState[uploadedFilesState?.length-1]?.guid)
        removeWaitingFile(uploadedFilesState[uploadedFilesState?.length-1]?.guid)
      }
    }
  },[isRemoveFile])
 
    useEffect(() => {
      eventEmitter.on(
        "UPLOAD_PROGRESS",
        percent => {
          if(percent<98){
           setUploadProgress(percent);
          }
          //setUploadProgress(50);
        }
      );
      return function cleanup() {
        eventEmitter.off("UPLOAD_PROGRESS")
      }
    }, [])
  useEffect(()=>{
      if(uploadedFilesState){
       onUploadStarted(true);
       setFilesLoaded(uploadedFilesState);
       const hasErrorFiles = uploadedFilesState.some( (file: { [x: string]: boolean; }) => file['hasError'] === true );
        if(/*!hasErrorFiles && */uploadedFilesState?.length){
          onUploadStopped(currentLoadingFile?.name);
        }
      }
  },[uploadedFilesState])

  useEffect(()=>{
    setShowLoader(false);
    if(fileUploadState?.fileUploadState){
      const fileUploadedData:any = fileUploadState?.fileUploadState;
      const fileUploaded = JSON.parse(fileUploadedData);
      const loadedContent = [...filesLoaded];
      if(!allowMultiple){
        const dropZone = document.querySelector(".dropzone-container");
        if(dropZone){
           dropZone?.classList?.remove("disableDrop");
        }
        if(fileUploaded?.documentGuid){
          setCurrentLoadedFileGuid(fileUploaded?.documentGuid);
        }
      }
      if(fileUploaded?.isSuccess){
        if(currentLoadingFile){
           currentLoadingFile.wordCount = fileUploaded?.data?.wordCount;
           currentLoadingFile.guid = fileUploaded?.documentGuid ? fileUploaded?.documentGuid : currentLoadingFile.guid;
           loadedContent?.push(currentLoadingFile);
          //  const currentWordCount = loadedContent.reduce((n: any, {wordCount}: any) => n + wordCount, 0);
          //  if(currentWordCount > max_word_count){
          //   loadedContent[loadedContent?.length-1].hasError = true;
          //   loadedContent[loadedContent?.length-1].errorMessage = "Error: Your file(s) contains more than 4000 words. Only the first 4000 words will be considered for course generation.";
          //  }
           setFilesLoaded(loadedContent);
        }
      }

      currentFileIndex++;
      if(filesToLoad && filesToLoad[currentFileIndex]){
        setCurrentLoadingFile(acceptedFiles[currentFileIndex]);
        // const uploadRequest:DocumentUploadRequest ={
        //   file: acceptedFiles[currentFileIndex],
        //   conversationGuid: metadata?.conversationGuid,
        //   messageGuid:  metadata?.messageGuid,
        //   frameworkName:  metadata?.frameworkName
        // }
        // dispatch(fileUploadActions.uploadFile(uploadRequest));
        dispatch(fileUploadActions.uploadFile({file:acceptedFiles[currentFileIndex],url:uploadUrl}));
        filesToLoad?.splice(currentFileIndex, 1);
      }
      else{
        setCurrentLoadingFile(undefined);
        currentFileIndex = 0;
        setFilesToLoad([]);
        setUploadProgress(100);
        const hasErrorFiles = loadedContent.some( (file: { [x: string]: boolean; }) => file['hasError'] === true );
        if(/*!hasErrorFiles && */loadedContent?.length){
          onUploadStopped(currentLoadingFile?.name);
        }
      }
      dispatch(fileUploadActions?.updateUploadedFiles(loadedContent));
      dispatch(fileUploadActions.clearFileUploadState());

    }
  },[fileUploadState?.fileUploadState])
  
  useEffect(()=>{
     setShowLoader(false);
     if(fileUploadState?.fileDeletionStatus && fileUploadState?.fileDeletionStatus?.isSuccess){
      const loadedContent = [...filesLoaded];
      const fileToRemoveIndex = loadedContent.findIndex((loadedFile:any) => loadedFile.guid==fileUploadState?.fileToRemove);
      if(loadedContent && fileToRemoveIndex!=-1){
        loadedContent?.splice(fileToRemoveIndex, 1); 
        const currentWordCount = loadedContent.reduce((n: any, {wordCount}: any) => n + wordCount, 0);
        if(currentWordCount > max_word_count){
            loadedContent[loadedContent?.length-1].hasError = true;
            loadedContent[loadedContent?.length-1].errorMessage = "Your file(s) contains more than 4000 words. Only the first 4000 words will be considered for course generation.";
        }
        setFilesLoaded(loadedContent);
        const hasErrorFiles = loadedContent.some( (file: { [x: string]: boolean; }) => file['hasError'] === true );
        if(/*!hasErrorFiles && */loadedContent?.length){
          onUploadStopped(currentLoadingFile?.name);
        }
        }
        dispatch(fileUploadActions?.updateUploadedFiles(loadedContent));
        dispatch(fileUploadActions.clearFileUploadState());
      }
  },[fileUploadState?.fileDeletionStatus])


  let currentFileIndex = 0 ;
  const onDrop = useCallback(async (acceptedFiles:any,fileRejections:any) => {
    acceptedFiles?.forEach((file:any)=>{
      file.guid = "id" + Math.random().toString(16).slice(2);
    });
    onUploadStarted(true);
    setUploadProgress(0);
    if(acceptedFiles?.length){
    setFilesToLoad(acceptedFiles);
    setCurrentLoadingFile(acceptedFiles[currentFileIndex]);
    // const uploadRequest:DocumentUploadRequest ={
    //   file: acceptedFiles[currentFileIndex],
    //   conversationGuid: metadata?.conversationGuid,
    //   messageGuid:  metadata?.messageGuid,
    //   frameworkName:  metadata?.frameworkName
    // }
    // dispatch(fileUploadActions.uploadFile(uploadRequest));
    
    if(!allowMultiple){
      if(currentLoadedFileGuid){
        removeLoadedFile(currentLoadedFileGuid);
      }
      //setFilesLoaded([]);
      const dropZone = document.querySelector(".dropzone-container");
      if(dropZone){
         dropZone?.classList?.add("disableDrop");
      }
    }
    dispatch(fileUploadActions.uploadFile({file:acceptedFiles[currentFileIndex],url:uploadUrl}));
    }
  }, [currentLoadedFileGuid])
  const {getRootProps, getInputProps, isDragActive,acceptedFiles, fileRejections } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    accept:acceptableFileTypes,
    multiple: allowMultiple
  });

  const formatBytes = (bytes:number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  const showFileProgress=(progress:number,bytes:number) => {
    const totalSize = formatBytes(bytes,2);
    const loadedSize = Math.floor(bytes * (progress / 100));
    const formattedLoadedSize = formatBytes(loadedSize);

    return `${formattedLoadedSize} of ${totalSize}`;
  }
  
  const removeLoadedFile = (fileGuid:string) =>{
    setShowLoader(true);
    dispatch(fileUploadActions?.removeFile(fileGuid));
    onUploadStarted(true);
  }
  const removeProgressingFile = () =>{
    setCurrentLoadingFile(undefined);
  }
  const removeWaitingFile = (guid:string) => {
    const fileToRemoveIndex = filesToLoad.findIndex((loadedFile) => loadedFile.guid==guid);
      if(filesToLoad?.length && fileToRemoveIndex!=-1){
        filesToLoad?.splice(fileToRemoveIndex, 1);
      }
    setShowLoader(true);
    
  }
  return (
    <>
    {showLoader &&(
      <DotLoader
      fullScreen
      />
    )}
    <div className="ai-file-uploader" data-file-uploader data-testid="file-uploader-wrapper">
      <div className="dropzone-container" {...getRootProps()}>
        <input {...getInputProps()} />
        <span className="file-uploader-label">
          <span className="icon"><img src={upload_icon} /></span>

          <span className="file-uploader-label-text" dangerouslySetInnerHTML={{__html: label}}></span>
        </span>
      </div>
    </div>
    <div className="ai-file-uploader-items">
          {filesLoaded?.map((file:any, i) =>      
          <React.Fragment key={'file_'+file?.guid}>          
            <div className={file?.hasError?'ai-file-uploader-items__item error-item':'ai-file-uploader-items__item'}> 
             <div className="item-details">
              <div className="item-details__fileIcon">
               <img src={file_icon} />
               <span>{file?.name}</span>
              </div>
              <div className="item-details__size">
                {/* <span className="item-size-details__custom">{file?.wordCount} words</span> */}
                {!file?.hasError && (
                <span className="item-size-details__size">{formatBytes(file?.size)}</span>
                )}
                {!file?.hasError && (
                <span className="icon" onClick={(event) => removeLoadedFile(file?.guid)}><img src={remove_icon} /></span>
                )}
                {file?.hasError && (
                <span className="remove" onClick={(event) => removeLoadedFile(file?.guid)}>Remove</span>
                )}
                
              </div> 
            </div>
          </div> 
          {file?.hasError && file?.errorMessage && (
          <div className='ai-file-uploader-items__errorMessage'>
             <img src={upload_error} />
             <span>{file?.errorMessage}</span>
          </div>
          )}
          </React.Fragment>
      )} 
      {currentLoadingFile && (
        <>
        <div className="ai-file-uploader-items__item">
        <div className="item-details">
          <div className="item-details__fileIcon">
           <img src={file_icon} />
           <span>{currentLoadingFile?.name}</span>
          </div>
          <div className="item-details__size">
            <span className="item-size-details__size">{showFileProgress(uploadProgress,currentLoadingFile?.size)}</span>
           {/* <span className="icon" onClick={(event) => removeProgressingFile()}><img src={IMAGE_COLLECTION.REMOVE_ICON} /></span> */}
          </div> 
        </div>
       </div>
      <div className="ai-file-uploader-items__itemprogress">
            <div className='progressBar' style={{"width" : uploadProgress+'%'}}>
            </div>
      </div>
      </>
      )}
    {filesToLoad?.map((file:any, i) => 
          <React.Fragment key={'file_'+file?.guid}>
            {i!=0 &&(
            <>
            <div className="ai-file-uploader-items__item">
             <div className="item-details">
               <div className="item-details__fileIcon">
                <img src={file_icon} />
                <span>{file?.name}</span>
               </div>
               <div className="item-details__size">
                 <span className="item-size-details__size">0 of {formatBytes(file?.size)}</span>
                <span className="icon" onClick={(event) => removeWaitingFile(file?.guid)}><img src={remove_icon} /></span>
               </div> 
             </div>
            </div> 
            <div className="ai-file-uploader-items__itemprogress">
            </div>
            </>
            )}
          </React.Fragment>
     )} 
    {/* {acceptedFiles?.map((file:any, i) => 
          <React.Fragment key={'file_'+file?.name}>
            <div className="ai-file-uploader-items__item">
             <div className="item-fileIcon">
              <img src={IMAGE_COLLECTION.FILE_ICON} />
              <span>{file?.name}</span>
             </div>
             <div className="item-size-details">
             <div className="item-size-details__progress">
               <div className='progressBar' style={{"width" : uploadProgress+'%'}}>
               </div>
              </div>
              <span className="item-size-details__custom">1000 words</span>
              <span className="item-size-details__size">{formatBytes(file?.size,1)}</span>
              <span className="icon"><img src={IMAGE_COLLECTION.REMOVE_ICON} /></span>
             </div>  
            </div> 
          </React.Fragment>
     )}  */}
    </div>
    </>
  );
};

export { FileUploader };