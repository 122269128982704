import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './configure-store';
import userReducer from '../store/user';
import chatReducer from 'modules/gpo/redux/chat';
import chatHistoryReducer from 'modules/gpo/redux/chatHistory';
import { fileUploadReducer } from '../file-upload';
import trainingMaterialsReducer from 'modules/gpo/redux/trainingMaterials';

const rootReducer = combineReducers({
    user: userReducer,
    chat : chatReducer,
    chatHistory : chatHistoryReducer,
    fileUpload:fileUploadReducer,
    trainingMaterials:trainingMaterialsReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
