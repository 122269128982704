import { addToApprovedResponseWatch, createConversationWatch, createQueryWatch, editApprovedResponsesWatch, getApprovedResponseSourcesWatch, getPDFDocumentWatch, saveTrainingMaterialLinkWatch, sendChatFeedbackWatch, sendUserFeedbackWatch, viewApprovedResponsesWatch } from 'modules/gpo/redux/chat/chat.saga';
import { getChatHistoryResultsWatch, getChatHistoryWatch, getSearchResultsWatch } from 'modules/gpo/redux/chatHistory/chatHistory.saga';
import { all } from 'redux-saga/effects';
import { getBotConversationTokenWatch, getCurrentUserAvatarWatch, getUserAvatarWatch, getUserProfileWatch, requestAccessWatch, setCurrentUserSessionWatch, signOutUserWatch } from '../store/user/user.saga';
import { fileRemoveWatch, fileUploadWatch } from '../file-upload/file-upload.saga';
import { getTrainingMaterialsWatch } from 'modules/gpo/redux/trainingMaterials/trainingMaterials.saga';

export default function* rootSaga() {
    yield all([
        getUserProfileWatch(),
        getCurrentUserAvatarWatch(),
        getUserAvatarWatch(),
        getBotConversationTokenWatch(),
        setCurrentUserSessionWatch(),
        signOutUserWatch(),
        sendUserFeedbackWatch(),
        getPDFDocumentWatch(),
        getChatHistoryWatch(),
        getSearchResultsWatch(),
        requestAccessWatch(),
        fileUploadWatch(),
        fileRemoveWatch(),
        createConversationWatch(),
        createQueryWatch(),
        getChatHistoryResultsWatch(),
        getApprovedResponseSourcesWatch(),
        addToApprovedResponseWatch(),
        viewApprovedResponsesWatch(),
        editApprovedResponsesWatch(),
        sendChatFeedbackWatch(),
        saveTrainingMaterialLinkWatch(),
        getTrainingMaterialsWatch()
    ]);
}
