import { ApiResult } from "common/models";
import { put, takeLatest } from "redux-saga/effects";
import { ChatHistoryService } from "./chatHistory.service";
import { chatHistoryActions } from "./chatHistory.slice";

const getChatHistory = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.getChatHistory(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.getChatHistoryFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.getChatHistorySuccess.type,
            payload: value
        });
    }
};

const getChatHistoryWatch = function* () {
    yield takeLatest(chatHistoryActions.getChatHistory.type,getChatHistory);
};

const getSearchResults = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.getSearchResults(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.getSearchResultsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.getSearchResultsSuccess.type,
            payload: value
        });
    }
};

const getSearchResultsWatch = function* () {
    yield takeLatest(chatHistoryActions.getSearchResults.type,getSearchResults);
};

const getChatHistoryResults = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatHistoryService.getChatHistoryResults(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatHistoryActions.getChatHistoryResultsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatHistoryActions.getChatHistoryResultsSuccess.type,
            payload: value
        });
    }
};

const getChatHistoryResultsWatch = function* () {
    yield takeLatest(chatHistoryActions.getChatHistoryResults.type,getChatHistoryResults);
};


export {
    getChatHistoryWatch,
    getSearchResultsWatch,
    getChatHistoryResultsWatch
    
};
