import { FC } from "react";
import "./ToolTip.scss";
import { closeIcon } from "assets/media";

type ToolTipProps = {
  message: string;
  onToolTipClose: () => void;
};

const ToolTip: FC<ToolTipProps> = ({ message ,onToolTipClose}) => {
  return (
    <>
      <div className="tooltip-container">
        <div className="tooltip-container__header">
          <div className="tooltip-container__header__triangle"></div>
        </div>
        <div className="tooltip-container__body">
          <div className="tooltip-container__body__content">
            {message}
            
          </div>
          <div className="tooltip-container__body__button">
            <img src={closeIcon} alt="closeIcon" onClick={onToolTipClose} />
          </div>
        </div>
      </div>
    </>
  );
};
export default ToolTip;
