import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/enums';
import { IErrorResponse, IValidationErrors } from 'common/models/api-service/error-response.model';
import { FileDeleteState } from 'common/models/file-delete-response.model';
import { FileUploadRequest, FileUploadState } from 'common/models/file-upload.model';

import { initialFileUploadState } from './file-upload.state';

const fileUploadSlice = createSlice({
    name: 'file-upload',
    initialState: initialFileUploadState,
    reducers: {
        uploadFile: (state, { payload }: PayloadAction<FileUploadRequest>) => {
            state.currentFile=payload;
        },
        uploadFileSuccess: (state, { payload }: PayloadAction<FileUploadState>) => {
           state.fileUploadState = payload;
        },
        uploadFileError: (state, { payload }: PayloadAction<IErrorResponse>) => {
           state.fileUploadState = undefined;
        },
        clearFileUploadState: (state) => {
            state.fileUploadState = undefined;
        },
        removeFile: (state, { payload }: PayloadAction<string>) => {
            state.fileToRemove = payload;
        },
        removeFileSuccess: (state, { payload }: PayloadAction<FileDeleteState>) => {
            state.fileDeletionStatus = payload;
        },
        removeFileError: (state, { payload }: PayloadAction<any>) => {
            state.fileDeletionStatus = undefined;
        }, 
        clearRemoveFileState: (state) => {
            state.fileDeletionStatus = undefined;
        },
        updateUploadedFiles:(state,{payload}:PayloadAction<any>)=>{
            state.uploadedFiles = payload;
        }
    }
});

export default fileUploadSlice.reducer;
export const fileUploadActions = fileUploadSlice.actions;
