import { ApiResult } from "common/models";
import { IChatFeedback, IChatFeedbackRequest } from "common/models/chat/chat-feedback-request.model";
import { HttpClient } from "common/services/http-client.service";
import chatApiConfig from "./chat.config";
import { ChatRequestModel } from "modules/gpo/models/chat/chat-coversation.model";
import { AddApprovedResponseRequest, EditApprovedResponseRequest, ViewApprovedResponsesRequest } from "modules/gpo/models/chat/approved-responses.model";
import { TrainingMaterialLinkRequest } from "common/models/training-material-link-request.model";

export class ChatBotService extends HttpClient {
    public static async sendUserFeedback(request:IChatFeedbackRequest): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.sendUserFeedback(request));
    }
    public static async getPDFDocument(documentGuid:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.getPDFDocument(documentGuid));
    }
    public static async createConversation():Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.createConversation());
    }
    public static async createQuery(chatRequest:ChatRequestModel):Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.createQuery(chatRequest));
    }
    public static async getApprovedResponseSources():Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.getApprovedResponseSources());
    }
    public static async addToApprovedResponse(addToApprovedResponseRequest:AddApprovedResponseRequest):Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.addToApprovedResponse(addToApprovedResponseRequest));
    }
    public static async viewApprovedResponses(viewApprovedResponseRequest:ViewApprovedResponsesRequest):Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.viewApprovedResponses(viewApprovedResponseRequest));
    }
    public static async editApprovedResponses(editApprovedResponseRequest:EditApprovedResponseRequest):Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.editApprovedResponses(editApprovedResponseRequest));
    }
    public static async sendChatFeedback(feedbackRequest:IChatFeedback):Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.sendChatFeedback(feedbackRequest));
    }
    public static async saveTrainingMaterialLink(documentLink:TrainingMaterialLinkRequest):Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatApiConfig.saveTrainingMaterialLink(documentLink));
    }
    
}
