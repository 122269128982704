import { FC, useContext, useEffect } from "react";
import { DateFormatterService } from "common/services/grid-date-formatter.service";
import UploadedStatus from "../UploadedStatus/UploadedStatus";
import { gpoHomeContext } from "modules/gpo/pages/GPOHome/GPOHomeContext/gpo.context";
import { adobeIcon, closeIcon } from "assets/media";

import "./UploadedTrainingMaterialsModal.scss";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { TrainingMaterialTypeCode, TrainingMaterialTypeLabel } from "modules/gpo/enums/uploaded-training-material.enum";

type UploadedTrainingMaterialsModalProps = {
  data?: string;
};
const UploadedTrainingMaterialsModal: FC<
  UploadedTrainingMaterialsModalProps
> = () => {
  const dispatch = useAppDispatch();
  const trainMaterials = useAppSelector(
    (state: RootState) => state.trainingMaterials
  );

  useEffect(() => {
    dispatch({ type: "trainingMaterials/getTrainingMaterials" });
  }, []);

  console.log(trainMaterials);

  const { gpoHomeContextValue, setGPOHomeContextValue } =
    useContext(gpoHomeContext);
  const showModal = gpoHomeContextValue?.showModal;
  const materialList = trainMaterials?.trainingMaterials || [];
  // const header = materialList
  //   .map((obj) => Object.keys(obj))
  //   .flat()
  //   .slice(0, 5);
    const header =["Type","Source","Uploaded Date","Ingested Date","Status"]
const getType=(typeId:number)=>{
  switch(typeId){
    case TrainingMaterialTypeCode.Document:
        return TrainingMaterialTypeLabel.Document
    case TrainingMaterialTypeCode.URL:
        return TrainingMaterialTypeLabel.URL
}

}
  const handleClose = () => {
    setGPOHomeContextValue?.({
      ...gpoHomeContextValue,
      showModal: false,
      showNotification: false,
      showFolderCheckToolTip:false
    });
  };
  return (
    <>
      {showModal && (
        <div className="upload__modal">
          <div className="upload__modal__container">
            <div className="upload__modal__container__header">
              <h3>Uploaded training materials</h3>
              <img
                src={closeIcon}
                alt="close-icon"
                width={24}
                height={24}
                onClick={() => handleClose()}
              ></img>
            </div>
            <div className="upload__modal__container__content">
              <div className="upload__modal__container__content__header">
                {header.map((item, index) => (
                  <div
                    className="upload__modal__container__content__header__item"
                    key={index}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="upload__modal__container__content__container">
                {materialList.map((item, index) => (
                  <>
                    <div
                      className="upload__modal__container__content__row"
                      key={index}
                    >
                      <div className="upload__modal__container__content__row__item">
                        {getType(item.sourceTypeId)}
                      </div>
                      <div className="upload__modal__container__content__row__item">
                        {item.sourceTypeId ===
                        TrainingMaterialTypeCode.Document ? (
                          <div className="source">
                            <img src={adobeIcon} alt="adobeIcon"></img>
                            {item.displayName}
                          </div>
                        ) : (
                          <div className="source">
                            {/* <div className="source__URL"> */}
                            <div className="source__URL" title={item.link}>
                              {item.link}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="upload__modal__container__content__row__item">
                        {DateFormatterService.covertUTCDateToLocal(item.uploadedDate,"DD MMM YYYY")}
                      </div>
                      <div className="upload__modal__container__content__row__item">
                        {item.ingestedDate?DateFormatterService.covertUTCDateToLocal(item.ingestedDate,"DD MMM YYYY"):""}
                      </div>
                      <div className="upload__modal__container__content__row__item">
                        <UploadedStatus
                          status={item.statusId}
                        ></UploadedStatus>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>

            <div className="upload__modal__container__footer">
              <div
                className="upload__modal__container__footer__button"
                onClick={() => handleClose()}
              >
                Close
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UploadedTrainingMaterialsModal;
