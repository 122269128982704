import { IChatFeedbackResponse } from "common/models/chat/chat-feedback-request.model";
import { UserChatHistory } from "common/models/chat/chat-history.model";
import { IDocumentDetails, IDocumentPage } from "common/models/chat/reference-document.model";
import { AddApprovedResponses, ViewApprovedResponses } from "modules/gpo/models/chat/approved-responses.model";
import { ChatConversationModel } from "modules/gpo/models/chat/chat-coversation.model";
import { ApprovedResponsesSourceResponses } from "modules/gpo/models/chat/chat-responses-sources.model";

interface IChatState{
    userFeedbackState:any;
    pdfDocumentDetails:IDocumentDetails|undefined;
    handsOffAcknowledged:Array<{id:string,message:string}>;
    viewDocURL:string;
    viewDocGuid:string;
    viewDocPageNum : IDocumentPage[];
    isDocumentOpen : boolean;
    viewDocProcessedDate:string;
    chatScrollHeight:any;
    agentLeavingAcknowledged:Array<{id:string,message:string}>;
    messageInputEnabled : boolean;
    messagesDisplayed : Array<string>;
    chatSearchResults : UserChatHistory[];
    searchMessages : UserChatHistory[]|undefined; 
    chatScrolled:boolean;
    conversationGuid:string|undefined;
    chatResponse:ChatConversationModel|undefined;
    approvedResponseSources:ApprovedResponsesSourceResponses|undefined;
    addToApprovedResponseState:AddApprovedResponses|undefined;
    viewApprovedResponses:ViewApprovedResponses|undefined;
    showPDFinFullView:boolean;
    editApprovedResponse:any;
    chatFeedbackResponse:IChatFeedbackResponse|undefined;
    documentLinkResponse:any;
    trainingMaterialUpdated:boolean;
    
}

const initalChatState: IChatState = {
    userFeedbackState:undefined,
    pdfDocumentDetails:undefined,
    handsOffAcknowledged:[],
    viewDocURL:"",
    viewDocGuid:"",
    viewDocPageNum:[],
    isDocumentOpen:false,
    viewDocProcessedDate:"",
    chatScrollHeight:undefined,
    agentLeavingAcknowledged:[],
    messageInputEnabled:false,
    messagesDisplayed:[],
    chatSearchResults:[],
    searchMessages:undefined,
    chatScrolled:false,
    conversationGuid:undefined,
    chatResponse:undefined,
    approvedResponseSources:undefined,
    addToApprovedResponseState:undefined,
    viewApprovedResponses:undefined,
    showPDFinFullView:false,
    editApprovedResponse:undefined,
    chatFeedbackResponse:undefined,
    documentLinkResponse:undefined,
    trainingMaterialUpdated:false
};

export { initalChatState };
