import React, { FC } from 'react';
import './TextArea.scss';

type TextAreaProps = {
    label?: string;
    name: string;
    value?: string;
    errorMessage?:string;
    onChange?: (value: any) => void;
    disabled?:boolean;
};
const TextArea: FC<TextAreaProps> = ({
    label,
    name,
    value,
    errorMessage,
    onChange,
    disabled=false
}: TextAreaProps) => {
    return (
        <>
            <div className={disabled?'material-input disabled':'material-input'}>
                <textarea onChange={(e) => onChange && onChange(e?.target?.value)} name={name} defaultValue = {value} required disabled={disabled}></textarea>
                <label>{label}</label>
                <span className='error-message'>{errorMessage}</span>
            </div>
        </>
    );
};

export default TextArea;
