import { APP_CONTROLLER } from 'common/config/endpoint.config';
import { ApiConfig } from 'common/models/api-service/api-config.model';

const fileUploadApiConfig = {
    uploadFile: (payload: File): any => {
        // return new ApiConfig(`${CONTROLLER.V2_COURSE}/${METHOD.DOCUMENT_UPLOAD}`, 'POST', '', payload,
        // {
        //     'content-type': 'multipart/form-data'
        // }
        // );
    },
    removeFile: (docGuid: string): any => {
        return new ApiConfig(`${APP_CONTROLLER.DOCUMENTS}/${docGuid}`, 'DELETE');
    },
};

export default fileUploadApiConfig;
