import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { ApiResult } from 'common/models';
import { FileUploadService } from './file-upload.service';
import { fileUploadActions } from '.';
import { FileUploadRequest } from 'common/models/file-upload.model';

/**
 *
 * Create Course
 */

const fileUpload = function* ({ payload }: PayloadAction<FileUploadRequest>) {
    const result: ApiResult<Blob> = yield FileUploadService.uploadFile(payload);    
        try {
            yield put({
                type: fileUploadActions.uploadFileSuccess.type,
                payload: result?.value
            });
        } catch (e) {
            yield put({
                type: fileUploadActions.uploadFileError.type,
                payload: ''
            });
        }
};

const fileUploadWatch = function* () {
    yield takeLatest(fileUploadActions.uploadFile.type, fileUpload);
};


const fileRemove = function* ({ payload }: PayloadAction<string>) {
    const result: ApiResult<Blob> = yield FileUploadService.removeFile(payload);    
        try {
            yield put({
                type: fileUploadActions.removeFileSuccess.type,
                payload: result?.value
            });
        } catch (e) {
            yield put({
                type: fileUploadActions.removeFileError.type,
                payload: ''
            });
        }
};

const fileRemoveWatch = function* () {
    yield takeLatest(fileUploadActions.removeFile.type, fileRemove);
};


export {
    fileUploadWatch,
    fileRemoveWatch
};
