import React, { FC } from 'react';
import './Input.scss';

type InputProps = {
    label: string;
    type: string;
    name: string;
    value?: string;
    errorMessage?:string;
    onChange?: (event: any) => void;
};
const Input: FC<InputProps> = ({ label, type, name, value, errorMessage,onChange }: InputProps) => {
    return (
        <>
            <div className="material-input">
                <input type={type} name={name} defaultValue={value} onChange={onChange} required/>
                <label>{label}</label>
                <span className='error-message'>{errorMessage}</span>
            </div>
        </>
    );
};

export default Input;
