import { ChatHistoryModel } from "common/models/chat/chat-history.model";
import { ChatHistoryContent } from "modules/gpo/models/chat-history/chat-history-content.model";

interface IChatHistoryState{
  chatHistory:ChatHistoryModel|undefined;
  searchTriggered:boolean;
  searchValue:string;
  searchResults:ChatHistoryModel|undefined;
  chatHistoryResults:ChatHistoryContent|undefined;
}

const initialChatHistoryState: IChatHistoryState = {
    chatHistory:undefined,
    searchTriggered:false,
    searchValue:"",
    searchResults:undefined,
    chatHistoryResults:undefined

};

export { initialChatHistoryState };
