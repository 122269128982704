import { close_btn } from "assets/images"
import { FC } from "react";
import "./CertifiedResponse.scss";
import { AddApprovedResponseRequest } from "modules/gpo/models/chat/approved-responses.model";

type CertifiedResponseProps = {
    onAdd:() => void
    onClose: (close:boolean) => void;
};
const CertifiedResponse: FC<CertifiedResponseProps> = ({
    onAdd,
    onClose
  }: CertifiedResponseProps) => {

  return(
    <div className="certified-response-wrapper">
           <div className="certified-response-container">
           <div className="certified-response-container__header">
            <span>Confirm certified response</span>
            <img className="close_btn" src={close_btn} onClick={(e) => onClose(false)}/>
           </div>
         <div className="certified-response-container__content">
            <p>From now on, the AI will return the same certified response for the question just asked. Are you sure?</p>
         </div>
         <div className="certified-response-container__footer">
            <button className="primary" onClick={(e) => onAdd()}>Confirm</button>
            <button onClick={(e) => onClose(false)}>Close</button>
        </div>
    </div>
    </div>
  )
}

export default CertifiedResponse;