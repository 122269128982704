import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initalTrainingMaterialsState } from "./trainingMaterials.state";
import { ITrainingMaterialsState } from "./trainingMaterials.state";

const trainingMaterialsSlice = createSlice({
    name: 'trainingMaterials',
    initialState: initalTrainingMaterialsState,
    reducers: {
        getTrainingMaterials: (state,{ payload }: PayloadAction<string>) => {
            console.log("Training Materials ")

        },
        getTrainingMaterialsSuccess: (state, { payload }: PayloadAction<ITrainingMaterialsState>) => {
            state.trainingMaterials = payload.trainingMaterials;
            console.log("Training Materials Sucess")
            //console.log("Training Materials",state);
            
        },
        getTrainingMaterialsFailed: (state, { payload }: PayloadAction<any>) => {
            state = initalTrainingMaterialsState;
            console.log("Errors",payload);
        }
    }
});

export const trainingMaterialsActions = trainingMaterialsSlice.actions;
export default trainingMaterialsSlice.reducer;