// Dev config
// export const ENV_CONFIG = {
//     CLIENT_ID: '60457b4c-f737-4b67-84c4-b100a790f76b',
//     AUTHORITY: 'https://login.microsoftonline.com/ey.com/',
//     REDIRECT_URI: "https://localhost:3000",
//     CACHE_LOCATION: 'sessionStorage',
//     DOMAIN : 'ey.com',
//     API_SCOPE:'api://d445f04e-fa84-44c2-a9c0-571bb0ea5816/user_impersonation',
//     API_URI:'https://usedcwtdlwap06.azurewebsites.net/api/',
//     TYPEWRITER_SPEED:50
// };

// Prod config
export const ENV_CONFIG = {
    CLIENT_ID: '#{CLIENT_ID}#',
    AUTHORITY: 'https://login.microsoftonline.com/ey.com/',
    REDIRECT_URI: '#{REDIRECT_URI}#',
    CACHE_LOCATION: 'sessionStorage',
    DOMAIN : '#{DOMAIN}#',
    API_SCOPE:'#{API_SCOPE}#',
    API_URI:'#{API_URI}#',
    TYPEWRITER_SPEED:'#{TYPEWRITER_SPEED}#'
};
