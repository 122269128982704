import { FC, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";

import { copy_text, defaultUserIcon } from "assets/media";
import { DateFormatterService } from "common/services/grid-date-formatter.service";
import "./ViewApprovedResponses.scss";
import { add_icon, close_btn, doc_icon } from "assets/images";
import { chatActions } from "modules/gpo/redux/chat";
import { ViewApprovedResponse, ViewApprovedResponseUser, ViewApprovedResponses, ViewApprovedResponsesRequest } from "modules/gpo/models/chat/approved-responses.model";
import DotLoader from "common/components/base/DotLoader/DotLoader";
import React from "react";

type ViewApprovedResponsesProps = {
conversationGuid:string;
previousMessageGuid:string;
onClose: (close:boolean) => void;
};

const ViewApprovedResponsesComp: FC<ViewApprovedResponsesProps> = ({
  conversationGuid,
  previousMessageGuid,
 onClose
}: ViewApprovedResponsesProps) => {
  const chatState = useAppSelector((state: RootState) => state.chat);
  const dispatch = useAppDispatch();
  const [showLoader,setShowLoader] = useState<boolean>(true);
  const [approvedResponses,setApprovedResponses] = useState<Array<ViewApprovedResponse>>([])

  const onCloseModal = () =>{
    dispatch(chatActions?.clearViewApprovedResponse());
    onClose(false);
  }
  useEffect(()=>{
   const request:ViewApprovedResponsesRequest = {
     conversationGuid: conversationGuid,
     previousMessageGuid: previousMessageGuid
   }
   dispatch(chatActions?.viewApprovedResponses(request));
  },[])
  useEffect(()=>{
    if(chatState?.viewApprovedResponses){
      setShowLoader(false);
      if(chatState?.viewApprovedResponses?.isSuccess){
        setApprovedResponses(chatState?.viewApprovedResponses?.approvedResponses)
      }
    }
  },[chatState?.viewApprovedResponses])
  const copyToClipBoard = (text:any,element?:any) =>{
    navigator.clipboard.writeText(text?text:"");
    if(element?.previousSibling){
      element?.previousSibling?.classList?.remove("hide");
      setTimeout(function(){
        element?.previousSibling?.classList?.add("hide");
      },400)

    }
  }
  return(
    <div className="view-approved-wrapper">
           {showLoader && (
            <DotLoader fullScreen/>
           )}
           <div className="view-approved-responses-container">
           <div className="view-approved-responses-container__header">
            <span>Certified Responses</span>
            <img className="close_btn" src={close_btn} onClick={(e) => onCloseModal()}/>
           </div>
         <div className="view-approved-responses-container__content">
            {approvedResponses?.length ?(
              <>
               {approvedResponses
                .map((response:ViewApprovedResponse,index) => (
                  <React.Fragment key={response?.approvedResponseGuid || index}>
                    <div className="approved-response-container">
                      <p className={approvedResponses?.length==1?"approved-response-container__content fill-content":"approved-response-container__content"} dangerouslySetInnerHTML={
                      {__html: response?.response?.replace(/\n/g, "</br>")}}></p>
                      <span>{DateFormatterService.covertUTCDateToLocal(response?.createdDate,"DD MMM YYYY h:mm a")}</span>
                      <div className="approved-response-container__link">
                        <span className="text-copied hide">Copied</span>
                        <button className="action-select action" onClick={(event) => copyToClipBoard(response?.response,event?.target)}><img src={copy_text} />Select Text</button>
                      </div>
                    </div>
                  </React.Fragment>
                )
                )}
               </>
              ):(
                <span className="no-data">No Certified Responses added yet.</span> 
              )
             }
            
         </div>
         <div className="view-approved-responses-container__footer">
            <button onClick={(e) => onCloseModal()}>Close</button>
        </div>
    </div>
    </div>

  );
}

  export default ViewApprovedResponsesComp;
