import { close_toast, error_toast, success_toast } from "assets/media/images";
import { FC, useEffect } from "react";
import "./Toaster.scss";

type ToasterProps = {
  message: string;
  isSuccess: boolean;
  closeTime?: number;
  onCloseToast: () => void;
};
const Toaster: FC<ToasterProps> = ({
  message,
  isSuccess,
  closeTime=3000,
  onCloseToast,
}: ToasterProps) => {
  useEffect(() => {
    setTimeout(() => {
      onCloseToast();
    }, closeTime);
  }, []);
  return (
    <div className={isSuccess ? "toast toast--success" : "toast toast--error"}>
      <div className="toast__body">
        {isSuccess ? <img src={success_toast} /> : <img src={error_toast} />}
        <p className="msg">{message}</p>
      </div>
      <div className="toast__close" onClick={onCloseToast}>
        <img src={close_toast} />
      </div>
    </div>
  );
};
export default Toaster;
