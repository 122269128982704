import { FC, useEffect, useState } from "react";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import "./ProvideFeedback.scss";
import { close_btn } from "assets/images";
import { FileUploader } from "common/components/custom/file-uploader/file-uploader";
import { chatActions } from "modules/gpo/redux/chat";
import DotLoader from "common/components/base/DotLoader/DotLoader";
import { FeedbackType } from "modules/gpo/enums/feedback-type.enum";
import Input from "common/components/custom/Input/Input";
import { ENV_CONFIG } from "common/config/env.config";
import { APP_CONTROLLER } from "common/config/endpoint.config";
import { fileUploadActions } from "common/redux/file-upload";
import { useSelector } from "react-redux";
import { TrainingMaterialLinkRequest } from "common/models/training-material-link-request.model";
import { ApprovedResponsesSources } from "modules/gpo/models/chat/chat-responses-sources.model";
import TextArea from "common/components/custom/Textarea/TextArea";

type ProvideFeedbackProps = {
  onClose: (close: boolean) => void;
};

const ProvideFeedback: FC<ProvideFeedbackProps> = ({
  onClose,
}: ProvideFeedbackProps) => {
  const dispatch = useAppDispatch();
  const chatState = useAppSelector((state: RootState) => state.chat);
  const uploadedFilesState = useSelector((state: RootState) => state.fileUpload.uploadedFiles);
  const [sources,setSources] =  useState<Array<ApprovedResponsesSources>>([]);
  const [citationText,setCitationText] = useState<string>("");
  const [feedbackText,setFeedbackText] = useState<string>("");
  const [isRemoveFile ,setIsRemoveFile]= useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [feedbackURLErrorMessage,setFeedbackURLErrorMessage] = useState<string>("");
  const [selectedSources,setSelectedSources] = useState<Array<string>>([]);
  const [selectedFeedbackType, setSelectedFeedbackType] =
    useState<FeedbackType>(FeedbackType.FILE);
  const [feedbackUrl, setFeedbackUrl] = useState<string>();
  const fileUploads = useSelector(
    (state: RootState) => state.fileUpload?.uploadedFiles
  );
  const [hasOtherSource, setHasOtherSource] = useState<boolean>(false);

  useEffect(()=>{
    console.log("dispatch approved response sources");
    dispatch(chatActions?.getApprovedResponseSources());
  },[])
  useEffect(()=>{
    if(chatState?.approvedResponseSources && chatState?.approvedResponseSources?.isSuccess){
       if( chatState?.approvedResponseSources?.sources?.length){
         setSources(chatState?.approvedResponseSources?.sources)
       }
    }
  },[chatState?.approvedResponseSources])

  useEffect(() => {
    setShowLoader(false);
    if (chatState?.documentLinkResponse?.isSuccess) {
      dispatch(chatActions?.updateTrainingMaterialStatus(true));
      dispatch(fileUploadActions?.clearFileUploadState());
      dispatch(fileUploadActions?.updateUploadedFiles([]));
      dispatch(chatActions?.clearTrainingMaterialLinkState());
      onClose(false);
    }
  }, [chatState?.documentLinkResponse]);
  const onAdd = () => {
    dispatch(chatActions?.updateTrainingMaterialStatus(false));
    let feedbackSources:any = [];
    selectedSources?.map(sourceGuid=>{
        const source = {
          sourceGuid:sourceGuid,
          sourceNote:sources?.filter(source => source?.sourceTypeId == 1)[0]?.sourceGuid == sourceGuid ? citationText : null
        } 
        feedbackSources?.push(source);
    })
    setTimeout(function () {
          const request: TrainingMaterialLinkRequest={
            documentSource: selectedFeedbackType,
            documentGuid: selectedFeedbackType==FeedbackType.FILE?uploadedFilesState && uploadedFilesState[uploadedFilesState?.length-1]?.guid:null,
            documentLink:selectedFeedbackType==FeedbackType.URL?feedbackUrl:null,
            documentFeedback: {
              feedback: feedbackText?feedbackText:null,
              feedbackSources: feedbackSources
            }
          }
          dispatch(chatActions?.saveTrainingMaterialLink(request));
          setShowLoader(true);
        
    }, 200);
  };
  const onUploadStopped = (event: any) => {
    console.log("Uplaod started");
  };
  const onUploadStarted = (event: any) => {
    console.log("Uplaod stopped");
  };
  const selectFeedbackType = (event: any) => {
    const typeSelected = event.currentTarget.value;
    if (typeSelected == FeedbackType.FILE) {
      setSelectedFeedbackType(FeedbackType.FILE);
    } else if (typeSelected == FeedbackType.URL) {
      setSelectedFeedbackType(FeedbackType.URL);
    }
  };
  const feedbackUrlChanged = (event: any) => {
    const feedbackUrlValue = event.currentTarget.value;
    if(!isValidUrl(feedbackUrlValue)){
      setFeedbackURLErrorMessage("Invalid URL");
    }
    else{
      setFeedbackURLErrorMessage("");
    }
    setFeedbackUrl(feedbackUrlValue);
  };
  const triggerClose = () => {
    if(uploadedFilesState?.length){
      const a:any= document.querySelector(".content-tabs-data .ai-file-uploader-items__item .icon");
      a.click()
    }
    dispatch(fileUploadActions?.clearFileUploadState());
    dispatch(fileUploadActions?.updateUploadedFiles([]));
    dispatch(chatActions?.clearTrainingMaterialLinkState());
    onClose(false);
    
  };
  const isValidUrl = (urlString:any) => {
    try { 
      return Boolean(new URL(urlString)); 
    }
    catch(e){ 
      return false; 
    }
}
  const handleCheckBoxChange = (checked:boolean,value:string,sourceType:number) =>{
    const hasOthersSelected = selectedSources?.includes(sources?.filter(source => source?.sourceTypeId == 1)[0]?.sourceGuid)
    if(sourceType == 1 && checked){
      setHasOtherSource(true);
    }
    if(hasOthersSelected){
      setHasOtherSource(true);
    }
    if( sourceType == 1 && !checked){
      setHasOtherSource(false);
    }
    if( sourceType!=1 && !hasOthersSelected){
      setHasOtherSource(false);
    }
    const sourcesCopy = selectedSources;
     if(checked){
      sourcesCopy?.push(value);
     }
     else{
      const index = sourcesCopy.indexOf(value);
      if (index > -1) {
        sourcesCopy.splice(index, 1); 
      }
     }
     setSelectedSources(sourcesCopy);
  }

  return (
    <>
      {showLoader && <DotLoader fullScreen />}
      <div className="provide-feedback-wrapper">
        <div className="provide-feedback-container">
          <div className="provide-feedback-container__header">
            <span>Provide Feedback</span>
            <img
              className="close_btn"
              alt="close-btn"
              src={close_btn}
              onClick={(e) => triggerClose()}
            />
          </div>
          <div className="provide-feedback-container__content">
             <div className="content-feedback">
              <TextArea 
                name={"response-citation"}
                label="Enter Feedback"
                disabled={false}
                onChange={setFeedbackText}
                />
              </div>
             <div className="content-info">
              <span>Where did you find that info (Click all that apply)</span>
              {sources?.map((source:ApprovedResponsesSources) => (
                <>
                <input type="checkbox" id={source?.sourceGuid} name={source?.sourceTypeId?.toString()} onChange={(e) => handleCheckBoxChange(e.target.checked,source?.sourceGuid,source?.sourceTypeId)}/>
                <label htmlFor={source?.sourceGuid}>
                  <span></span>{source?.sourceName}
                </label>
                </>
              ))} 
            </div>
            { hasOtherSource && (
              <div className="content-link">
              <TextArea 
                name={"response-citation"}
                label="Where did you find that info"
                disabled={false}
                onChange={setCitationText}
                />
              </div>
            )}
            <div className="content-tabs">
              <div className="content-tabs__radio">
                <input
                  type="radio"
                  id="file"
                  name="feedback-type"
                  value="DocumentUpload"
                  defaultChecked
                  onChange={selectFeedbackType}
                />
                <label htmlFor="file">Upload Source File</label>
              </div>
              <div className="content-tabs__radio">
                <input
                  type="radio"
                  id="url"
                  name="feedback-type"
                  value="Url"
                  onChange={selectFeedbackType}
                />
                <label htmlFor="url">URL</label>
              </div>
            </div>
            <div className="content-tabs-data">
              {selectedFeedbackType == FeedbackType.FILE && (
                <FileUploader
                  label={"Drag and drop the document or </br><b>browse</b>"}
                  onUploadStopped={onUploadStopped}
                  onUploadStarted={onUploadStarted}
                  isRemoveFile={isRemoveFile}
                  allowMultiple={false}
                  acceptableFileTypes={{
                    "application/pdf": [],
                  }}
                  uploadUrl={ENV_CONFIG.API_URI + `${APP_CONTROLLER.DOCUMENTS}`}
                />
              )}
              {selectedFeedbackType == FeedbackType.URL && (
                <Input
                  type={"text"}
                  name={"feedback-url"}
                  onChange={feedbackUrlChanged}
                  label={"URL"}
                  errorMessage={feedbackURLErrorMessage}
                />
              )}
            </div>
          </div>
          <div className="provide-feedback-container__footer">
            <button className="primary" onClick={(e) => onAdd()} disabled={uploadedFilesState?.length||isValidUrl(feedbackUrl)?false:true}>
              Submit
            </button>
            <button onClick={(e) => triggerClose()}>Close</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProvideFeedback;
