import { APP_CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";


const chatHistoryApiConfig = {
    getChatHistory: (searchText:string): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHAT}/${METHOD.HISTORY}`, 'GET',`&searchText=${searchText?searchText:""}`),
    getChatHistoryResults: (dayCount?:number): ApiConfig<any> => {
       if(dayCount){
          return new ApiConfig(`${APP_CONTROLLER.CHATS}`, 'GET',`&dayCount=${dayCount}`)
       }
       else{
         return new ApiConfig(`${APP_CONTROLLER.CHATS}`, 'GET');
       }
    }
};

export default chatHistoryApiConfig;
