import { ApiResult } from "common/models";
import { put, takeLatest } from "redux-saga/effects";
import { ChatBotService } from "./chat.service";
import { chatActions } from "./chat.slice";

const sendUserFeedback = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.sendUserFeedback(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.sendUserFeedbackFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.sendUserFeedbackSuccess.type,
            payload: value
        });
    }
};

const sendUserFeedbackWatch = function* () {
    yield takeLatest(chatActions.sendUserFeedback.type, sendUserFeedback);
};

const getPDFDocument = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.getPDFDocument(action?.payload);
    if (hasErrors) {
        yield put({
            type: chatActions.getPDFDetailsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type:  chatActions.getPDFDetailsSuccess.type,
            payload: value
        });
    }
};


const getPDFDocumentWatch = function* () {
    yield takeLatest(chatActions.getPDFDetails.type, getPDFDocument);
};

const createConversation = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.createConversation();

    if (hasErrors) {
        yield put({
            type: chatActions.createCoversationFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.createCoversationSuccess.type,
            payload: value
        });
    }
};

const createConversationWatch = function* () {
    yield takeLatest(chatActions.createCoversation.type, createConversation);
};

const createQuery = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.createQuery(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.createQueryFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.createQuerySuccess.type,
            payload: value
        });
    }
};

const createQueryWatch = function* () {
    yield takeLatest(chatActions.createQuery.type, createQuery);
};

const getApprovedResponseSources = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.getApprovedResponseSources();

    if (hasErrors) {
        yield put({
            type: chatActions.getApprovedResponseSourcesFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.getApprovedResponseSourcesSuccess.type,
            payload: value
        });
    }
};

const getApprovedResponseSourcesWatch = function* () {
    yield takeLatest(chatActions.getApprovedResponseSources.type, getApprovedResponseSources);
};

const addToApprovedResponse = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.addToApprovedResponse(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.addToApprovedResponseFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.addToApprovedResponseSuccess.type,
            payload: value
        });
    }
};

const addToApprovedResponseWatch = function* () {
    yield takeLatest(chatActions.addToApprovedResponse.type, addToApprovedResponse);
};

const viewApprovedResponses = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.viewApprovedResponses(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.viewApprovedResponsesFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.viewApprovedResponsesSuccess.type,
            payload: value
        });
    }
};

const viewApprovedResponsesWatch = function* () {
    yield takeLatest(chatActions.viewApprovedResponses.type, viewApprovedResponses);
};

const editApprovedResponses = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.editApprovedResponses(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.editApprovedResponsesFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.editApprovedResponsesSuccess.type,
            payload: value
        });
    }
};

const editApprovedResponsesWatch = function* () {
    yield takeLatest(chatActions.editApprovedResponses.type, editApprovedResponses);
};

const sendChatFeedback = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.sendChatFeedback(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.sendChatFeedbackFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.sendChatFeedbackSuccess.type,
            payload: value
        });
    }
};

const sendChatFeedbackWatch = function* () {
    yield takeLatest(chatActions.sendChatFeedback.type, sendChatFeedback);
};

const saveTrainingMaterialLink = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.saveTrainingMaterialLink(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.saveTrainingMaterialLinkFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.saveTrainingMaterialLinkSuccess.type,
            payload: value
        });
    }
};

const saveTrainingMaterialLinkWatch = function* () {
    yield takeLatest(chatActions.saveTrainingMaterialLink.type, saveTrainingMaterialLink);
};



export {
    sendUserFeedbackWatch,getPDFDocumentWatch,createConversationWatch,createQueryWatch,getApprovedResponseSourcesWatch,addToApprovedResponseWatch,viewApprovedResponsesWatch,editApprovedResponsesWatch,sendChatFeedbackWatch,saveTrainingMaterialLinkWatch
};
