import { ApiStatus } from 'common/enums';
import { FileDeleteState } from 'common/models/file-delete-response.model';
import {FileUploadState} from "common/models/file-upload.model"

interface IFileUploadState{
   fileUploadState : FileUploadState|undefined;
   currentFile:any|undefined;
   fileDeletionStatus:FileDeleteState|undefined;
   fileToRemove:string;
   uploadedFiles:Array<any>|undefined;
}

const initialFileUploadState: IFileUploadState = {
    fileUploadState:undefined,
    currentFile:undefined,
    fileDeletionStatus:undefined,
    fileToRemove:"",
    uploadedFiles:undefined
};

export { initialFileUploadState };
