import React, { FC, useEffect, useRef, useState } from "react";
import Typewriter from "typewriter-effect";
import TypeWriterEffect from 'react-typewriter-effect';
import "./DualMessageAttachment.scss";
import { text } from "body-parser";
import { ENV_CONFIG } from "common/config/env.config";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { chatActions } from "modules/gpo/redux/chat";
import { add_icon, doc_icon, list_icon, mark_accurate, maximize_icon, maximize_png, provide_feedback, shield_icon } from "assets/images";
import ResponseRecommendationModal from "../ResponseRecommendationModal/ResponseRecommendationModal";
import AddApprovedResponses from "../AddApprovedResponses/AddApprovedResponses";
import { ChatDocumentPageModel, ChatResponse } from "modules/gpo/models/chat/chat-coversation.model";
import ViewApprovedResponsesComp from "../ViewApprovedResponses/ViewApprovedResponses";
import ProvideFeedback from "../ProvideFeedback/ProvideFeedback";

type DualMessageAttachmentProps = {
  content:string;
  contentUrl?:string;
  contentType?:string;
  name?:string;
  gpoRole:string;
  chatID:string;
  hasHandsOff?:boolean;
  showTyping:boolean;
  stopTypingOnTabSwitch:boolean;
  isWelcomeMessage:boolean;
  confidence?:number|undefined;
  isSingleMessage:boolean;
  list:Array<ChatResponse>;
  trainedResponse:ChatResponse|undefined;
  currentResponse:ChatResponse|undefined;
  isHistory:boolean;
  onViewDoc: (pageNum: ChatDocumentPageModel[], documentGuid:string,activityId:string,showFullView:boolean,docProcessedDate:string) => void;
};
const DualMessageAttachment: FC<DualMessageAttachmentProps> = ({
content, 
contentUrl, 
contentType, 
name, 
gpoRole, 
chatID,
hasHandsOff,
showTyping,
stopTypingOnTabSwitch,
isWelcomeMessage,
confidence,
isSingleMessage,
list,
trainedResponse,
currentResponse,
isHistory,
onViewDoc
}: DualMessageAttachmentProps) => {
  const chatState = useAppSelector((state: RootState) => state.chat);
  const [showMaximize,setShowMaximize] = useState<boolean>(false);
  const [showViewedResponse,setShowViewedResponse] = useState<boolean>(false);
  const [addApprovedResponse,setAddApprovedResponse] = useState<boolean>(false);
  const [showProvideFeedback,setShowProvideFeedback] = useState<boolean>(false);
  const chatStateDispatch = useAppDispatch();
  const [chatScrolled, setChatScrolled] = useState(false);
  const [currentAnswer,setCurrentAnswer] = useState<string>("");
  const [currentConversationGuid,setCurrentConversationGuid] = useState<string|undefined>("");
  const [currentPreviousMessageGuid,setCurrentPreviousMessageGuid] = useState<string|undefined>("");

  const envSpeed:any = ENV_CONFIG.TYPEWRITER_SPEED;
  const typeWritingSpeed = parseInt(envSpeed);
  let i=0;
  let displayString = content?.split(" ");
  const clearTimerRef:any = useRef();

  useEffect(()=>{
    if(stopTypingOnTabSwitch){
        clearTimeout(clearTimerRef?.current);
        const para =   document.getElementById('live-chat_'+chatID);
        const textSpan = para?.querySelector('.content');
        if(textSpan){
          textSpan.innerHTML = content.replace(/\n/g, "</br>");
        chatStateDispatch(chatActions?.updateMessageDisplayed(chatID));
        if(!hasHandsOff){
        chatStateDispatch(chatActions?.updateMessageInputState(true));
        }
        const textSpanCursor = para?.querySelector('.add-cursor-animate');
        if(textSpanCursor){
          textSpanCursor?.classList?.add('hide');
        }
        scrollToBottom();
       }
    }
  },[stopTypingOnTabSwitch])

  useEffect(()=>{
    setChatScrolled(chatState?.chatScrolled);
  },[chatState?.chatScrolled])

  useEffect(() => {
    if(!showTyping && content && gpoRole=="bot"){
      chatStateDispatch(chatActions?.updateMessageDisplayed(chatID));
      if(!hasHandsOff){
      chatStateDispatch(chatActions?.updateMessageInputState(true));
      }
    }
    else{
    chatStateDispatch(chatActions?.updateMessageInputState(false));
    const para =  document.getElementById('live-chat_'+chatID);
    const textSpan = para?.querySelector('.content');
    const cursor= para?.querySelector('.add-cursor-animate');
      if(cursor){
        cursor?.classList?.remove('hide');
      }
      if(textSpan){
        textSpan.innerHTML = "";
        chatStateDispatch(chatActions?.updateChatScrolled(false));
        if(isWelcomeMessage){
          const delayTypingFn = setTimeout(() => {
            typeWriter();
          }, 100)
          return () => clearTimeout(delayTypingFn)
          
          }
          else{
           typeWriter();
          }

      }
    }

  }, [content,chatID]);
  const typeWriter = () =>{

    if (i < content?.length) {
      const para =   document.getElementById('live-chat_'+chatID);
      const textSpan = para?.querySelector('.content');
      if(textSpan){
        if(content.charAt(i)=='\n'){
          textSpan.innerHTML += "</br>";
        }
        else{
        textSpan.innerHTML += content.charAt(i);
        }
      i++;
      clearTimerRef.current = setTimeout(typeWriter, typeWritingSpeed);
      }
    }
    if(i == content.length){
      chatStateDispatch(chatActions?.updateMessageDisplayed(chatID));
      if(!hasHandsOff){
      chatStateDispatch(chatActions?.updateMessageInputState(true));
      }
      const para =  document.getElementById('live-chat_'+chatID);
      const textSpan = para?.querySelector('.add-cursor-animate');
      if(textSpan){
        textSpan?.classList?.add('hide');
      }
     }
      scrollToBottom();
  }
  const scrollToBottom = () =>{
    const messagesWrapper:any = document.getElementsByClassName('gpo-custom-chat-messages')[0];
    if(messagesWrapper){
      messagesWrapper.scroll({ top: messagesWrapper.scrollHeight});
    }
  }

  const openAddToApprovedModal = (answer:string) => {
    setCurrentAnswer(answer);
    setAddApprovedResponse(!addApprovedResponse);
  }

  const openViewApprovedModal = (coversationGuid:string|undefined,previousGuid:string|undefined) => {
    setCurrentConversationGuid(coversationGuid);
    setCurrentPreviousMessageGuid(previousGuid);
    if(coversationGuid && previousGuid){
      setShowViewedResponse(true);
    }
  }

  const handleViewApprovedModal = () =>{
    setShowViewedResponse(false);
  }
 
  switch (contentType) {
    case "image/gif":
    case "image/jpeg":
    case "image/png":
    case "image/svg":
    case "image/svg+xml":
      return <img alt={name} src={contentUrl} />;

    case "text/markdown":
    case "text/plain":
 
      { 
      if(gpoRole=="bot"){
        return(
          <>
          {(showTyping==undefined||showTyping )&& (
            <p className=" chatMessage text-message" id={isHistory?'history_'+chatID:'live-chat_'+chatID}>
            <span className="content"></span>
            <span className="add-cursor-animate"></span>
            </p>
          )}
          {(showTyping!=undefined && !showTyping ) && (      
            //  <p id={'live-chat_'+chatID}>{content}</p>
            <>             
             {showMaximize && (
               <ResponseRecommendationModal
               list={list}
                currentResponse={currentResponse} 
                trainedResponse={trainedResponse}
                onClose={setShowMaximize}
                onViewDoc={onViewDoc}
                />
            )} 
           {showViewedResponse && (
               <ViewApprovedResponsesComp
                    conversationGuid={currentConversationGuid?currentConversationGuid:""} 
                    previousMessageGuid={currentPreviousMessageGuid?currentPreviousMessageGuid:""}               
                    onClose={handleViewApprovedModal} 
                    />
                )}
            {addApprovedResponse && (
               <AddApprovedResponses
                onClose={setAddApprovedResponse} 
                questionText={list?.filter(msg=>currentResponse?.previousMessageGuid == msg?.messageGuid) ? list?.filter(msg=>msg?.messageGuid == currentResponse?.previousMessageGuid)[0]?.messageText : ""} 
                answerText={currentAnswer}              
                />
            )} 
            {showProvideFeedback && (
               <ProvideFeedback
                 onClose={setShowProvideFeedback} 
               />
            )} 
            <div className="chatContent" id={isHistory?'history_'+chatID:'live-chat_'+chatID}>
                 <div className="chatContent__untrained"  id={'chat_'+chatID}>
                    <span className="header">Publicly Available AI</span>
                     <span className="content" dangerouslySetInnerHTML={
                      {__html: content?.replace(/\n/g, "</br>")}}></span>
                    {/* <div className="actions">
                        <button  className="actions__add"   onClick={(event) => openAddToApprovedModal(content)}><img src={add_icon} />Add to approved responses</button>
                        <button className="actions__view" onClick={(event) => openViewApprovedModal(currentResponse?.conversationGuid,currentResponse?.previousMessageGuid)}><img src={add_icon}/>View approved responses</button>
                    </div> */}
                 </div>
                 <div className="chatContent__trained" id={trainedResponse?'chat_'+trainedResponse?.messageGuid:''}>
                    <div className="trained-header">
                       <span className="header">EY Trained AI</span>
                       {list?.filter(msg=>currentResponse?.previousMessageGuid == msg?.messageGuid)?.length && list?.filter(msg=>currentResponse?.previousMessageGuid == msg?.messageGuid)[0]?.hasCertifiedResponses && (                    
                         <button className="actions__view" onClick={(event) => openViewApprovedModal(currentResponse?.conversationGuid,currentResponse?.previousMessageGuid)}><img src={shield_icon}/>View certified responses</button>
                       )}
                    </div>
                    {trainedResponse ?(
                     <span className="content" dangerouslySetInnerHTML={
                      {__html: trainedResponse?.messageText.replace(/\n/g, "</br>")}}></span>
                    ):(
                      <span className="content">Sorry, I am not able to help you right now as I have no documents in the custom knowledge base.</span>
                    )
                    }
                    <div className="actions">
                        {/* <button disabled={trainedResponse?false:true} className="actions__add" onClick={(event) => openAddToApprovedModal(trainedResponse?.messageText ? trainedResponse?.messageText : "")}><img src={add_icon} />Add to approved responses</button> */}
                        {/* {trainedResponse && trainedResponse?.score && trainedResponse?.score>0 && ( */}
                           <>
                           {trainedResponse && trainedResponse?.score && trainedResponse?.score>0 && (
                           <div className="actions__doc">
                              <div className="citation-modal">
                                  <div className="citation-modal__label">
                                  <span className="label">Confidence Score</span>
                                  <span className="label">Source</span>
                                  <span className="label">Page Number</span>
                                  </div>
                                  <div className="citation-modal__values">
                                  <span title="View Document" className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',false,trainedResponse?.documents?trainedResponse?.documents[0]?.processedDate:"")}>{trainedResponse?.score} %</span>
                                  <span title="View Document" className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',false,trainedResponse?.documents?trainedResponse?.documents[0]?.processedDate:"")}>{trainedResponse?.documents?trainedResponse?.documents[0]?.link:""}</span>
                                  <span title="View Document" className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',false,trainedResponse?.documents?trainedResponse?.documents[0]?.processedDate:"")}>Page {trainedResponse?.documents?trainedResponse?.documents[0]?.pages.map((obj:ChatDocumentPageModel) => obj.pageNumber)?.join(','):[]}</span>
                                  </div>
                                  {/* <div className="citation-modal__score">
                                     <span className="label">Confidence Score</span>
                                     <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',false)}>{trainedResponse?.score}</span>
                                  </div>
                                 <div className="citation-modal__source">
                                   <span className="label">Source</span>
                                   <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',false)}>{trainedResponse?.documents?trainedResponse?.documents[0]?.link:""}</span>
                                 </div>
                                <div className="citation-modal__pages">
                                  <span className="label">Page Number</span>
                                   <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',false)}>Page {trainedResponse?.documents?trainedResponse?.documents[0]?.pages.map((obj:ChatDocumentPageModel) => obj.pageNumber)?.join(','):[]}</span>
                                </div> */}
                            </div>
                            <div className="doc-citation">
                            <span>Confidence: </span>
                            {/* <span className="doc-confidence excellent">Excellent</span> */}
                            {trainedResponse?.score > 70 ? (
                            <span className="doc-confidence excellent">Excellent</span>
                            ): trainedResponse?.score >= 50 && trainedResponse?.score <= 70 ?(
                            <span className="doc-confidence medium">Medium</span>
                           ):(
                            <span className="doc-confidence low">Low</span>
                           )
                           }
                            </div>
                           
                           <img className="doc-button" title="Show Citation" src={doc_icon} />
                           </div>
                           )}
                          {trainedResponse && trainedResponse?.score && trainedResponse?.score>0 && (
                          <button className="actions__mark-accurate" onClick={(event) => openAddToApprovedModal(trainedResponse?.messageText ? trainedResponse?.messageText : "")}><img src={mark_accurate}/>Mark as Accurate</button>
                          )}
                          <button className="actions__provide-feedback" onClick={(event) => setShowProvideFeedback(true) }><img src={provide_feedback}/>Provide feedback</button>

                          </>
                        {/* )} */}
                    </div>
                 </div>
                 <div className="chatContent__maximize">
                   <img src={maximize_png} onClick={(event) => setShowMaximize(!showMaximize)}/>
                 </div>
             </div>
            </>
             
          )}

          </>      
        )     
      }
      else{
        return <p className="chatMessage" id={'live-chat_'+chatID}>{content}</p>
     }
    }
    default:
      if (contentUrl) {
        return (
          <a href={contentUrl} rel="noopener noreferrer" target="_blank">
            {name}
          </a>
        );
      } else {
        return <pre>{JSON.stringify(content, null, 2)}</pre>;
      }
  }
};

export default DualMessageAttachment;
