import { APP_CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { IChatFeedback, IChatFeedbackRequest } from "common/models/chat/chat-feedback-request.model";
import { TrainingMaterialLinkRequest } from "common/models/training-material-link-request.model";
import { AddApprovedResponseRequest, EditApprovedResponseRequest, ViewApprovedResponsesRequest } from "modules/gpo/models/chat/approved-responses.model";
import { ChatRequestModel } from "modules/gpo/models/chat/chat-coversation.model";


const chatApiConfig = {
 
    sendUserFeedback: (feedbackRequest:IChatFeedbackRequest): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHAT}/${METHOD.FEEDBACK}`, 'POST','',feedbackRequest),
    
    getPDFDocument: (documentGuid:string): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.DOCUMENTS}/${documentGuid}`, 'GET'),

    createConversation: (): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHATS}`, 'POST'),

    createQuery: (chatRequest:ChatRequestModel): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHATS}/${chatRequest?.coversationGuid}/${METHOD.MESSAGE}`, 'POST','',chatRequest?.query),
    
    getApprovedResponseSources: (): ApiConfig<any> =>
       new ApiConfig(`${METHOD.SOURCES}`, 'GET'),
   
    addToApprovedResponse : (approvedResponseRequest:AddApprovedResponseRequest) :ApiConfig<any> =>
    new ApiConfig(`${APP_CONTROLLER.APPROVED_RESPONSES}`, 'POST','',approvedResponseRequest),

    viewApprovedResponses : (viewApprovedResponseRequest:ViewApprovedResponsesRequest) :ApiConfig<any> =>
    new ApiConfig(`${APP_CONTROLLER.CHATS}/${viewApprovedResponseRequest.conversationGuid}/${METHOD.MESSAGE}/${viewApprovedResponseRequest.previousMessageGuid}/${APP_CONTROLLER.APPROVED_RESPONSES}`, 'GET'),

    editApprovedResponses : (editApprovedResponse:EditApprovedResponseRequest) :ApiConfig<any> =>
    new ApiConfig(`${APP_CONTROLLER.CHATS}/${editApprovedResponse.conversationGuid}/${METHOD.MESSAGE}/${editApprovedResponse.messageGuid}/${APP_CONTROLLER.APPROVED_RESPONSES}/${editApprovedResponse?.approvedResponseGuid}`, 'POST','',editApprovedResponse.response),

    sendChatFeedback : (feedbackRequest:IChatFeedback) :ApiConfig<any> =>
    new ApiConfig(`${APP_CONTROLLER.CHATS}/${feedbackRequest.conversationGuid}/${METHOD.MESSAGE}/${feedbackRequest.messageGuid}/${METHOD.FEEDBACK}`, 'POST','',{"like":feedbackRequest.like}),

    saveTrainingMaterialLink : (documentLink:TrainingMaterialLinkRequest) :ApiConfig<any> =>
    new ApiConfig(`${APP_CONTROLLER.TRAINING_MATERIALS}`, 'POST','',documentLink),
};

export default chatApiConfig;
