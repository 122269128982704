import { createContext } from "react";


export type GPOHomeContextValue = {
    showModal: boolean;
    showNotification?: boolean;
    showFolderCheckToolTip?: boolean;
}

export const defaultGPOHomeContextValue: GPOHomeContextValue = { 
    showModal: false ,
    showNotification: false,
    showFolderCheckToolTip: false
};

export type GPOHomeContext={
    gpoHomeContextValue: GPOHomeContextValue;
    setGPOHomeContextValue?: (val: GPOHomeContextValue) => void;
}
export const gpoHomeContext= createContext <GPOHomeContext>({
    gpoHomeContextValue: defaultGPOHomeContextValue
});
