import { FC, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";

import { copy_text, defaultUserIcon, edit_button, view_button } from "assets/media";

import "./ResponseRecommendationModal.scss";
import { add_icon, close_btn, doc_icon, mark_accurate, provide_feedback } from "assets/images";
import { ChatDocumentPageModel, ChatResponse } from "modules/gpo/models/chat/chat-coversation.model";
import AddApprovedResponses from "../AddApprovedResponses/AddApprovedResponses";
import { ViewApprovedResponse, ViewApprovedResponsesRequest } from "modules/gpo/models/chat/approved-responses.model";
import { chatActions } from "modules/gpo/redux/chat";
import DotLoader from "common/components/base/DotLoader/DotLoader";
import React from "react";
import ResponseDetails from "../ResponseDetails/ResponseDetails";
import EditApprovedResponse from "../EditApprovedResponse/EditApprovedResponse";
import ProvideFeedback from "../ProvideFeedback/ProvideFeedback";
import { DateFormatterService } from "common/services/grid-date-formatter.service";

type ResponseRecommendationModalProps = {
currentResponse:ChatResponse|undefined;
trainedResponse:ChatResponse|undefined;
list:Array<ChatResponse>|undefined;
onClose: (close:boolean) => void;
onViewDoc: (pageNum: ChatDocumentPageModel[], documentGuid:string,activityId:string,showFullView:boolean,docProcessedDate:string) => void;

};

const ResponseRecommendationModal: FC<ResponseRecommendationModalProps> = ({
 currentResponse,
 trainedResponse,
 list,
 onClose,
 onViewDoc
}: ResponseRecommendationModalProps) => {
  const [currentAnswer,setCurrentAnswer] = useState<string>("");
  const [addApprovedResponse,setAddApprovedResponse] = useState<boolean>(false);
  const chatState = useAppSelector((state: RootState) => state.chat);
  const dispatch = useAppDispatch();
  const [approvedResponses,setApprovedResponses] = useState<Array<ViewApprovedResponse>>([])
  const [showLoader,setShowLoader] = useState<boolean>(true);
  const [showResponseDetail,setShowResponseDetail] = useState<boolean>(false)
  const [showProvideFeedback,setShowProvideFeedback] = useState<boolean>(false)
  const [currentApprovedResponse,setCurrentApprovedResponse] = useState<ViewApprovedResponse>();
  const [showEditResponseDetail,setShowEditResponseDetail] = useState<boolean>(false)


  useEffect(()=>{
    const request:ViewApprovedResponsesRequest = {
      conversationGuid: currentResponse?.conversationGuid?currentResponse?.conversationGuid:"",
      previousMessageGuid: currentResponse?.previousMessageGuid? currentResponse?.previousMessageGuid:""
    }
    if(currentResponse?.conversationGuid && currentResponse?.previousMessageGuid){
      dispatch(chatActions?.viewApprovedResponses(request));
    }
   },[])

   useEffect(()=>{
    if(chatState?.viewApprovedResponses){
      setShowLoader(false);
      if(chatState?.viewApprovedResponses?.isSuccess){
        setApprovedResponses(chatState?.viewApprovedResponses?.approvedResponses)
      }
    }
  },[chatState?.viewApprovedResponses])


  const openAddToApprovedModal = (answer:string) => {
    setCurrentAnswer(answer);
    setAddApprovedResponse(!addApprovedResponse);
  }
  const onCloseModal = () =>{
    dispatch(chatActions?.clearViewApprovedResponse());
    onClose(false);
  }

  const onViewResponse = ( response:ViewApprovedResponse) =>{
    setCurrentApprovedResponse(response);
    setShowResponseDetail(true);
  }
  const onEditResponse = ( response:ViewApprovedResponse) =>{
    setCurrentApprovedResponse(response);
    setShowEditResponseDetail(true);
  }
  const copyToClipBoard = (text:any,element?:any) =>{
    navigator.clipboard.writeText(text?text:"");
    if(element?.previousSibling){
      element?.previousSibling?.classList?.remove("hide");
      setTimeout(function(){
        element?.previousSibling?.classList?.add("hide");
      },400)

    }
  }
  useEffect(()=>{
    if(chatState.editApprovedResponse){
      const request:ViewApprovedResponsesRequest = {
        conversationGuid: currentResponse?.conversationGuid?currentResponse?.conversationGuid:"",
        previousMessageGuid: currentResponse?.previousMessageGuid? currentResponse?.previousMessageGuid:""
      }
      if(currentResponse?.conversationGuid && currentResponse?.previousMessageGuid){
        dispatch(chatActions?.viewApprovedResponses(request));
      }
    }
  },[chatState.editApprovedResponse])
  return(
    <>
    {addApprovedResponse && (
      <AddApprovedResponses
       onClose={setAddApprovedResponse} 
       questionText={list?.filter(msg=>currentResponse?.previousMessageGuid == msg?.messageGuid) ? list?.filter(msg=>msg?.messageGuid == currentResponse?.previousMessageGuid)[0]?.messageText : ""} 
       answerText={currentAnswer}              
       />
   )}
   {showResponseDetail && currentApprovedResponse &&(
    <ResponseDetails 
    approvedResponse={currentApprovedResponse}
    onClose={setShowResponseDetail}
    />
   )}
   {showEditResponseDetail && currentApprovedResponse &&(
    <EditApprovedResponse 
    approvedResponse={currentApprovedResponse}
    currentResponse={currentResponse}
    onClose={setShowEditResponseDetail}
    />
   )}
   {showProvideFeedback && (
      <ProvideFeedback
        onClose={setShowProvideFeedback} 
        />
    )}    
   {showLoader && (
      <DotLoader fullScreen/>
    )}
    <div className="response-recommendation-container">
         <div className="response-recommendation-container__header">
            <span>Response recommendation</span>
            <img className="close_btn" src={close_btn} onClick={(e) => onCloseModal()}/>
         </div>
         <div className="response-recommendation-container__content">

                 
                 <div className="untrained">
                    <span className="header">Publicly Available AI</span>
                    <div className="content">
                    <span className="message" dangerouslySetInnerHTML={
                    {__html: currentResponse?.messageText?currentResponse?.messageText.replace(/\n/g, "</br>"):""}}></span>
                    <div className="actions">
                        <div className="action">
                        <span className="text-copied hide">Copied</span>
                        <button disabled={currentResponse?false:true} className="action-select action" onClick={(event) => copyToClipBoard(currentResponse?.messageText,event?.target)}><img src={copy_text} />Select Text</button>
                        </div>
                        {/* <div className="action-add action">
                        <button disabled={currentResponse?false:true} className="actions__add" onClick={(event) => openAddToApprovedModal(currentResponse?.messageText ? currentResponse?.messageText : "")}><img src={add_icon} />Add to approved responses</button>
                        </div> */}
                    </div>
                    </div>
                </div>
                 <div  className="approved-responses">
                 <span className="header">Certified Responses</span>
                 <div className="content">
                 {approvedResponses?.length ?(
              <>
               {approvedResponses
                .map((response:ViewApprovedResponse,index) => (
                  <React.Fragment key={response?.approvedResponseGuid || index}>
                    <div className="approved-response-container">
                      <p className={approvedResponses?.length==1?"approved-response-container__content fill-content":"approved-response-container__content"} dangerouslySetInnerHTML={
                      {__html: response?.response?.replace(/\n/g, "</br>")}}></p>
                      <span className="approved-response-container__date">{DateFormatterService.covertUTCDateToLocal(response?.createdDate,"DD MMM YYYY h:mm a")}</span>
                      <div className="approved-response-container__link">
                        <div  className="link-action">
                        <span className="text-copied hide">Copied</span>
                        <button className="action-select action" onClick={(event) => copyToClipBoard(response?.response,event?.target)}><img src={copy_text} />Select Text</button>
                        </div>
                        <div className="link-action" >
                           {/* <img src={view_button} />
                           <span>View</span> */}
                           <button className=" action"onClick={(e)=>{onViewResponse(response)}}><img src={view_button} />View</button>
                        </div>
                        {/* <div className="link-action">
                           
                          <button className="action" onClick={(e)=>{onEditResponse(response)}}><img src={edit_button} />Edit</button>

                        </div> */}
                      </div>
                    </div>
                  </React.Fragment>
                )
                )}
               </>
              ):(
                <span className="no-data">No Certified Responses added yet.</span>
                )
             } 
                    {/* <div className="actions"></div> */}
                </div>
                 </div>
                 <div className="trained">
                    <span className="header">EY Trained AI </span>
                    <div className="content">
                    {trainedResponse ?(
                     <span className="message" dangerouslySetInnerHTML={
                      {__html: trainedResponse?.messageText.replace(/\n/g, "</br>")}}></span>
                    ):(
                      <span className="message">Sorry, I am not able to help you right now as I have no documents in the custom knowledge base.</span>
                    )
                    }
                        <div className="actions">
                        {/* <div className="actions__doc">
                            <span>Confidence: </span>
                            <span className="doc-confidence">Excellent</span>
                            <img className="doc-button" src={doc_icon} />
                        </div> */}
                        <div className="action-copy">
                        <span className="text-copied hide">Copied</span>
                        <button disabled={trainedResponse?false:true} className="action-select action" onClick={(event) => copyToClipBoard(trainedResponse?.messageText,event?.target)}><img src={copy_text} />Select Text</button>
                        </div>
                        {/* <div className="action-add action">
                        <button disabled={trainedResponse?false:true} className="actions__add" onClick={(event) => openAddToApprovedModal(trainedResponse?.messageText ? trainedResponse?.messageText : "")}><img src={add_icon} />Add to approved responses</button>
                        </div> */}
                        <div className="action-others">
                        {trainedResponse && trainedResponse?.score && trainedResponse?.score>0 && (
                          <button className="actions__mark-accurate" onClick={(event) => openAddToApprovedModal(trainedResponse?.messageText ? trainedResponse?.messageText : "")}><img src={mark_accurate}/>Mark as Accurate</button>
                          )}
                          <button className="actions__provide-feedback" onClick={(event) => setShowProvideFeedback(true) }><img src={provide_feedback}/>Provide feedback</button>
                        </div>
                    </div>
                    </div>
                    <>
                    {trainedResponse?.score && trainedResponse?.score>0 && (
                      <>
                      <div className="actions__doc">
                         <div className="citation-modal">
                         <div className="citation-modal__label">
                                  <span className="label">Confidence Score</span>
                                  <span className="label">Source</span>
                                  <span className="label">Page Number</span>
                                  </div>
                                  <div className="citation-modal__values">
                                  <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',true,trainedResponse?.documents?trainedResponse?.documents[0]?.processedDate:"")}>{trainedResponse?.score} %</span>
                                  <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',true,trainedResponse?.documents?trainedResponse?.documents[0]?.processedDate:"")}>{trainedResponse?.documents?trainedResponse?.documents[0]?.link:""}</span>
                                  <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',true,trainedResponse?.documents?trainedResponse?.documents[0]?.processedDate:"")}>Page {trainedResponse?.documents?trainedResponse?.documents[0]?.pages.map((obj:ChatDocumentPageModel) => obj.pageNumber)?.join(','):[]}</span>
                                  </div>
                             {/* <div className="citation-modal__score">
                                <span className="label">Confidence Score</span>
                                <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',true)}>{trainedResponse?.score}</span>
                             </div>
                            <div className="citation-modal__source">
                              <span className="label">Source</span>
                              <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',true)}>{trainedResponse?.documents?trainedResponse?.documents[0]?.link:""}</span>
                            </div>
                           <div className="citation-modal__pages">
                             <span className="label">Page Number</span>
                              <span className="doc-value" onClick={() => onViewDoc(trainedResponse?.documents?trainedResponse?.documents[0]?.pages:[],trainedResponse?.documents?trainedResponse?.documents[0]?.documentGuid:'',currentResponse?.messageGuid?currentResponse?.messageGuid:'',true)}>Page {trainedResponse?.documents?trainedResponse?.documents[0]?.pages.map((obj:ChatDocumentPageModel) => obj.pageNumber)?.join(','):[]}</span>
                           </div> */}
                       </div>
                       <div className="doc-citation">
                       <span>Confidence: </span>
                       {trainedResponse?.score > 70 ? (
                       <span className="doc-confidence excellent">Excellent</span>
                       ): trainedResponse?.score >= 50 && trainedResponse?.score <= 70 ?(
                       <span className="doc-confidence medium">Medium</span>
                      ):(
                       <span className="doc-confidence low">Low</span>
                      )
                      }
                       </div>
                      
                      <img title="Show Citation" className="doc-button" src={doc_icon} />
                     </div>
                     </>
                    //  <div className="confidence">
                    //  <span>Confidence: </span>
                    //  {trainedResponse?.score && trainedResponse?.score > 70 ? (
                    //   <span className="doc-confidence excellent">Excellent</span>
                    //  ): trainedResponse?.score && trainedResponse?.score >= 50 && trainedResponse?.score <= 70 ?(
                    //   <span className="doc-confidence medium">Medium</span>
                    //  ):(
                    //   <span className="doc-confidence low">Low</span>
                    //  )
                    //  }
                     
                    //  <img className="doc-button" title="View Document" src={doc_icon}/>
                    // </div>
                    )}
                    </>
                    

                 </div>
                 
         </div>
    </div>
    </>
  )
}

export default ResponseRecommendationModal;
