import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatHistoryModel } from 'common/models/chat/chat-history.model';
import { initialChatHistoryState } from './chatHistory.state';
import { ChatHistoryContent } from 'modules/gpo/models/chat-history/chat-history-content.model';


const chatHistorySlice = createSlice({
    name: 'chatHistory',
    initialState: initialChatHistoryState,
    reducers: {
        getChatHistory: (state, { payload }: PayloadAction<string>) => {
        },
        getChatHistorySuccess: (state, { payload }: PayloadAction<ChatHistoryModel>) => {
            state.chatHistory = payload;
        },
        getChatHistoryFailed: (state, { payload }: PayloadAction<any>) => {
            state.chatHistory = initialChatHistoryState.chatHistory;
            console.log("Errors",payload);
        },
        updateSearchTriggered: (state, { payload }: PayloadAction<boolean>) => {
            state.searchTriggered = payload;
        },
        updateSearchValue: (state, { payload }: PayloadAction<string>) => {
            state.searchValue = payload;
        },
        getSearchResults: (state, { payload }: PayloadAction<string>) => {
        },
        getSearchResultsSuccess: (state, { payload }: PayloadAction<ChatHistoryModel>) => {
            state.searchResults = payload;
        },
        getSearchResultsFailed: (state, { payload }: PayloadAction<any>) => {
            state.searchResults = initialChatHistoryState.searchResults;
            console.log("Errors",payload);
        },
        clearSearchResults : (state) => {
            state.searchResults = initialChatHistoryState.searchResults;
        },
        getChatHistoryResults: (state, { payload }: PayloadAction<number|undefined>) => {
        },
        getChatHistoryResultsSuccess: (state, { payload }: PayloadAction<ChatHistoryContent>) => {
            state.chatHistoryResults = payload;
        },
        getChatHistoryResultsFailed: (state, { payload }: PayloadAction<any>) => {
            state.chatHistoryResults = initialChatHistoryState.chatHistoryResults;
            console.log("Errors",payload);
        }, 
       
    }
});

export const chatHistoryActions = chatHistorySlice.actions;

export default chatHistorySlice.reducer;
