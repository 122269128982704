/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-prototype-builtins */
/*
Module Name: app
Description : Date Formatter service
Created By : Bibitha Bahuleyan
Created Date : 25-04-2022
Modified by:
*/
import { DATE_FORMAT_DAYS_LONG } from "../config/date-format-days.config";
import { DATE_FORMAT_MONTHS_SHORT } from "../config/date-format-months_short.config";
import { DATE_FORMAT_MONTHS_LONG } from "../config/date_format_months_long.config";
import { HttpClient } from "./http-client.service";

export class DateFormatterService extends HttpClient {
  private static defaultDateFormat = "dd-MM-yyyy h:mm a tz";

  public static convertLocalDateToUTC(
    formattedDateValue: any,
    format?: any
  ): any {
    return this.convertFormattedDateToJSDate(formattedDateValue, format);
  }

  public static covertUTCDateToLocal(utcDate: any, format?: string): any {
    if (!utcDate.endsWith("Z")) {
      utcDate = utcDate + "Z";
    }

    const jsDate = new Date(utcDate.toLocaleString());
    return this.convertJSDateToLocal(jsDate, format);
  }

  private static convertFormattedDateToJSDate(
    formattedDate: any,
    format?: any
  ) {
    let dateString: any;
    let jsDate: Date = new Date();
    if (formattedDate?.indexOf("-") != -1) {
      dateString = formattedDate?.split("-");
      jsDate = this.createDateAndFormatMatchArrays(dateString, "-", format);
    } else if (formattedDate.indexOf("/") != -1) {
      dateString = formattedDate.split("/");
      jsDate = this.createDateAndFormatMatchArrays(dateString, "/", format);
    } else if (formattedDate.indexOf("\\") != -1) {
      dateString = formattedDate.split("\\");
      jsDate = this.createDateAndFormatMatchArrays(dateString, "\\", format);
    } else if (formattedDate.indexOf(",") != -1) {
      dateString = formattedDate.split(",");
      jsDate = this.createDateAndFormatMatchArrays(dateString, ",", format);
    }

    return this.convertJSDateToUTC(jsDate);
  }

  private static convertJSDateToUTC(jsDate: Date): string {
    return jsDate.toISOString();
  }

  private static createDateAndFormatMatchArrays(
    dateArray: any[],
    dateSeperator: string,
    format?: any
  ): Date {
    const dateStringMatchArray: any[] = [];
    const formatStringMatchArray: any[] = [];
    dateArray?.forEach((dateSplit: string) => {
      const splitOnSpace = dateSplit.split(" ");
      splitOnSpace?.forEach((spaceSplit: string) => {
        const splitOnColon = spaceSplit.split(":");
        splitOnColon?.forEach((colonSplit: string) => {
          if (colonSplit?.trim() != "") dateStringMatchArray.push(colonSplit);
        });
      });
    });
    const currentFormat = format ? format : this.defaultDateFormat;
    const formatArray = currentFormat?.split(dateSeperator);

    formatArray?.forEach((format: string) => {
      const splitOnSpace = format.split(" ");
      splitOnSpace?.forEach((spaceSplit: string) => {
        const splitOnColon = spaceSplit.split(":");
        splitOnColon?.forEach((colonSplit: string) => {
          if (colonSplit?.trim() != "") formatStringMatchArray.push(colonSplit);
        });
      });
    });
    const matchObject: any = {};
    formatStringMatchArray.forEach(
      (key, i) => (matchObject[key] = dateStringMatchArray[i])
    );
    let dateValue = 1;
    let monthValue = 1;
    let yearValue = 2022;
    let hoursValue = 0;
    let minutesValue = 0;
    let secondsValue = 0;
    if (
      matchObject.hasOwnProperty("DD") ||
      matchObject.hasOwnProperty("dd") ||
      matchObject.hasOwnProperty("D") ||
      matchObject.hasOwnProperty("d")
    ) {
      const date =
        matchObject.DD || matchObject.D || matchObject.dd || matchObject.d;
      dateValue = parseInt(date);
    }

    if (matchObject.hasOwnProperty("M") || matchObject.hasOwnProperty("MM")) {
      const month = matchObject.M || matchObject.MM;
      monthValue = parseInt(month);
    } else if (matchObject.hasOwnProperty("MMM")) {
      const month = matchObject.MMM;
      const monthObject: any = DATE_FORMAT_MONTHS_SHORT;
      const monthFromShortmonths: any = Object.keys(monthObject).find(
        (key) => monthObject[key] === month
      );
      monthValue = parseInt(monthFromShortmonths);
    } else if (matchObject.hasOwnProperty("MMMM")) {
      const month = matchObject.MMMM;
      const monthObject: any = DATE_FORMAT_MONTHS_LONG;
      const monthFromLongmonths: any = Object.keys(monthObject).find(
        (key) => monthObject[key] === month
      );
      monthValue = parseInt(monthFromLongmonths);
    }

    if (
      matchObject.hasOwnProperty("Y") ||
      matchObject.hasOwnProperty("YYYY") ||
      matchObject.hasOwnProperty("yyyy") ||
      matchObject.hasOwnProperty("y")
    ) {
      const year =
        matchObject.y || matchObject.yyyy || matchObject.YYYY || matchObject.Y;
      yearValue = parseInt(year);
    } else if (
      matchObject.hasOwnProperty("YY") ||
      matchObject.hasOwnProperty("yy")
    ) {
      let year = matchObject.yy || matchObject.YY;
      year = "20" + year;
      yearValue = parseInt(year);
    }

    if (matchObject.hasOwnProperty("hh") && !matchObject.hasOwnProperty("a")) {
      const hours = matchObject.hh;
      hoursValue = parseInt(hours);
    } else if (
      matchObject.hasOwnProperty("hh") &&
      matchObject.hasOwnProperty("a")
    ) {
      const hours = matchObject.hh;
      const amPmValue = matchObject.a;
      hoursValue = parseInt(hours);
      if (hoursValue < 12 && amPmValue == "PM") {
        hoursValue = hoursValue + 12;
      }
    } else if (matchObject.hasOwnProperty("h")) {
      const hours = matchObject.h;
      hoursValue = parseInt(hours);
      if (hoursValue < 12) {
        hoursValue = hoursValue + 12;
      }
    }
    if (matchObject.hasOwnProperty("mm")) {
      const minutes = matchObject.mm;
      minutesValue = parseInt(minutes);
    }
    if (matchObject.hasOwnProperty("ss")) {
      const seconds = matchObject.ss;
      secondsValue = parseInt(seconds);
    }
    const jsdate = new Date(
      yearValue,
      monthValue,
      dateValue,
      hoursValue,
      minutesValue,
      secondsValue
    );
    return jsdate;
  }

  private static convertJSDateToLocal(value: Date, format: any): string {
    const month = (value?.getMonth() + 1).toString();
    const year = value?.getFullYear().toString();
    const yearShort = year?.slice(2);
    const date = value.getDate().toString();
    const longdate = date?.length == 1 ? "0" + date : date;
    const day = value?.getDay();
    const longDay =
      DATE_FORMAT_DAYS_LONG[day as keyof typeof DATE_FORMAT_DAYS_LONG];
    const shortMonthString =
      DATE_FORMAT_MONTHS_SHORT[
        (value?.getMonth() + 1) as keyof typeof DATE_FORMAT_MONTHS_SHORT
      ];
    const longMonthString =
      DATE_FORMAT_MONTHS_LONG[
        (value?.getMonth() + 1) as keyof typeof DATE_FORMAT_MONTHS_LONG
      ];
    const hours = value?.getHours().toString();
    let minutes = value?.getMinutes().toString();
    minutes = minutes.length == 1 ? "0" + minutes : minutes;
    let seconds = value?.getSeconds().toString();
    seconds = seconds.length == 1 ? "0" + seconds : seconds;
    const longMonthNumeric = month?.length == 1 ? "0" + month : month;
    const hours12 =
      value
        ?.toLocaleTimeString("en-US", {
          hour: "numeric",
          hour12: true,
          minute: "numeric"
        })
        .indexOf("PM") != -1
        ? value.getHours() > 12
          ? (value?.getHours() - 12).toString()
          : value?.getHours().toString()
        : hours;
    const hours12long = hours?.length == 1 ? "0" + hours : hours;
    const amPMValue =
      value
        ?.toLocaleTimeString("en-US", {
          hour: "numeric",
          hour12: true,
          minute: "numeric"
        })
        .indexOf("PM") != -1
        ? "PM"
        : "AM";
    const gmtShort = value
      ?.toString()
      ?.split("GMT")[1]
      ?.split(" ")[0]
      ?.slice(0, 3);
    const gmtLong =
      value?.toString()?.split("GMT")[1]?.split(" ")[0]?.slice(0, 3) +
      ":" +
      value?.toString()?.split("GMT")[1]?.split(" ")[0]?.slice(3);
    let currentFormat = format ? format : this.defaultDateFormat;
    const withTimeZone = currentFormat?.indexOf("tz") != -1 ? true : false;
    if (withTimeZone) {
      currentFormat = currentFormat?.split("tz")[0]?.trim();
    }
    let formattedDate = "";
    if (currentFormat) {
      formattedDate = JSON.parse(JSON.stringify(currentFormat));
      if (
        currentFormat.indexOf("hh") != -1 &&
        currentFormat.indexOf("a") == -1
      ) {
        formattedDate = formattedDate?.replace(/h/, hours);
      } else if (
        currentFormat.indexOf("hh") != -1 &&
        currentFormat.indexOf("a") != -1
      ) {
        formattedDate = formattedDate?.replace(/h/, hours12long);
      } else if (currentFormat.indexOf("h") != -1) {
        formattedDate = formattedDate?.replace(/h/g, hours12);
      }

      if (currentFormat.indexOf("a") != -1)
        formattedDate = formattedDate?.replace(/a/, amPMValue);

      if (currentFormat.indexOf("zzzz") != -1)
        formattedDate = formattedDate?.replace(/zzzz/, "GMT" + gmtLong);
      else if (currentFormat.indexOf("z") != -1)
        formattedDate = formattedDate?.replace(/z/, "GMT" + gmtShort);

      if (
        currentFormat.indexOf("dd") != -1 ||
        currentFormat.indexOf("DD") != -1
      )
        formattedDate = formattedDate?.replace(/dd|DD/, longdate);
      else if (
        currentFormat.indexOf("d") != -1 ||
        currentFormat.indexOf("D") != -1
      )
        formattedDate = formattedDate?.replace(/d|D/, date);

      if (currentFormat.indexOf("MMMM") != -1)
        formattedDate = formattedDate?.replace(/MMMM/, longMonthString);
      else if (currentFormat.indexOf("MMM") != -1)
        formattedDate = formattedDate?.replace(/MMM/, shortMonthString);
      else if (currentFormat.indexOf("MM") != -1)
        formattedDate = formattedDate?.replace(/MM/, longMonthNumeric);
      else if (currentFormat.indexOf("M") != -1)
        formattedDate = formattedDate?.replace(/M/, month);

      if (
        currentFormat.indexOf("yyyy") != -1 ||
        currentFormat.indexOf("YYYY") != -1
      )
        formattedDate = formattedDate?.replace(/yyyy|YYYY/, year);
      else if (
        currentFormat.indexOf("yy") != -1 ||
        currentFormat.indexOf("YY") != -1
      )
        formattedDate = formattedDate?.replace(/yy|YY/, yearShort);
      else if (
        currentFormat.indexOf("y") != -1 ||
        currentFormat.indexOf("Y") != -1
      )
        formattedDate = formattedDate?.replace(/y|Y/, year);

      if (currentFormat.indexOf("EEEE") != -1) {
        formattedDate = formattedDate?.replace(/EEEE/, longDay);
      }

      if (currentFormat.indexOf("mm") != -1)
        formattedDate = formattedDate?.replace(/mm/, minutes);

      if (currentFormat.indexOf("ss") != -1)
        formattedDate = formattedDate?.replace(/ss/, seconds);

      if (withTimeZone) {
        let dateString: any = value?.toString()?.match(/\((.+)\)/);
        if (dateString) {
          dateString = dateString[1]?.split(" ");
          if (dateString.length > 1) {
            dateString = dateString.map(([first]: any) => first).join("");
          }
        }
        formattedDate = formattedDate + " " + dateString;
      }
    }
    return formattedDate;
  }

  public static setDefaultDateFormat(format: string) {
    let dateFormat = format;
    if (format.indexOf("tz") != -1) {
      dateFormat = format.split("tz")[0]?.trim();
    }
    // eslint-disable-next-line no-useless-escape
    const pattern = /^[\-:,\\\/hmsdDMyYaEzZ ]+$/;
    const correctFormat = pattern.test(dateFormat);
    if (correctFormat) {
      this.defaultDateFormat = format;
    }
  }

  public static getDefaultDateFormat() {
    return this.defaultDateFormat;
  }

  public static setDateFormat(date: string) {
    if (!date.endsWith("Z")) {
      date = date + "Z";
    }
    return date;
  }
}
