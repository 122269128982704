import { FC, useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";

import { defaultUserIcon } from "assets/media";

import "./EditApprovedResponse.scss";
import { add_icon, close_btn, doc_icon } from "assets/images";
import TextArea from "common/components/custom/Textarea/TextArea";
import Input from "common/components/custom/Input/Input";
import { FileUploader } from "common/components/custom/file-uploader/file-uploader";
import { chatActions } from "modules/gpo/redux/chat";
import { ApprovedResponsesSources } from "modules/gpo/models/chat/chat-responses-sources.model";
import DotLoader from "common/components/base/DotLoader/DotLoader";
import { AddApprovedResponseRequest, EditApprovedResponseRequest, ViewApprovedResponse } from "modules/gpo/models/chat/approved-responses.model";
import { ChatResponse } from "modules/gpo/models/chat/chat-coversation.model";


type EditApprovedResponseProps = {
approvedResponse:ViewApprovedResponse,
currentResponse:ChatResponse|undefined,
onClose: (close:boolean) => void;
};

const EditApprovedResponse: FC<EditApprovedResponseProps> = ({
  approvedResponse,
  currentResponse,
  onClose
}: EditApprovedResponseProps) => {
  
  const dispatch = useAppDispatch();
  const chatState = useAppSelector((state: RootState) => state.chat);
  const [sources,setSources] =  useState<Array<ApprovedResponsesSources>>([]);
  const [showLoader,setShowLoader] = useState<boolean>(false);
  const [citationText,setCitationText] = useState<string>(approvedResponse?.link);
  const [newQuestionText,setNewQuestionText] = useState<string>( approvedResponse?.question);
  const [newAnswerText,setNewAnswerText] = useState<string>(approvedResponse?.response);
  const [selectedSources,setSelectedSources] = useState<Array<string>>(approvedResponse?.sources?.map(src=>src?.sourceGuid));
  const [buttonDisabled,setButtonDisabled] =  useState<boolean>(false);

  const onUpdate = () =>{
    const request:EditApprovedResponseRequest ={
      conversationGuid: currentResponse?.conversationGuid?currentResponse?.conversationGuid:"",
      messageGuid: currentResponse?.previousMessageGuid?currentResponse?.previousMessageGuid:"",
      approvedResponseGuid: approvedResponse?.approvedResponseGuid,
      response:{
        question: newQuestionText,
        response: newAnswerText,
        link: citationText,
        sourceGuids: selectedSources
      }
    }
    dispatch(chatActions?.editApprovedResponses(request));
    setShowLoader(true);
  }
  const onCitationChange = (text:string) => {
     setButtonDisabled(false);
     setCitationText(text);
  }
  const handleCheckBoxChange = (checked:boolean,value:string) =>{
    setButtonDisabled(false);
    const sourcesCopy = selectedSources;
    if(checked){
     sourcesCopy?.push(value);
    }
    else{
     const index = sourcesCopy.indexOf(value);
     if (index > -1) {
       sourcesCopy.splice(index, 1); 
     }
    }
    console.log("sources:",sourcesCopy);
    setSelectedSources(sourcesCopy);
 }
 useEffect(()=>{
    setButtonDisabled(false);
 },[citationText])
  useEffect(()=>{
    console.log("dispatch approved response sources");
    //dispatch(chatActions?.getApprovedResponseSources());
  },[])
  useEffect(()=>{
    if(chatState?.approvedResponseSources && chatState?.approvedResponseSources?.isSuccess){
       if( chatState?.approvedResponseSources?.sources?.length){
         setSources(chatState?.approvedResponseSources?.sources)
       }
    }
  },[chatState?.approvedResponseSources])
  useEffect(()=>{
    setShowLoader(false);
    if(chatState.editApprovedResponse){
      if(chatState?.editApprovedResponse?.isSuccess){
        dispatch(chatActions?.clearEditApprovedResponse());
        onClose(false);
      }
    }
  },[chatState.editApprovedResponse])
  return(
    <>
    {showLoader && (
      <DotLoader fullScreen/>
    )}
    
    <div className="edit-approved-response-wrapper">
           <div className="edit-approved-response-container">
           <div className="edit-approved-response-container__header">
            <span>Edit to approved responses</span>
            <img className="close_btn" src={close_btn} onClick={(e) => onClose(false)}/>
           </div>
         <div className="edit-approved-response-container__content">
            <div className="content-question">
                <TextArea 
                name={"response-question"}
                label="Question"
                value = {newQuestionText}
                onChange={setNewQuestionText}
               />
            </div>
            <div className="content-response">
                <TextArea 
                name={"response-answer"}
                label="Response"
                value = {newAnswerText}
                onChange={setNewAnswerText}
                />
            </div>
            <div className="content-info">
              <span>Where did you find that info</span>
              {sources?.map((source:ApprovedResponsesSources) => (
                <>
                <input type="checkbox" id={source?.sourceGuid} defaultChecked={approvedResponse?.sources?.map(src=>src?.sourceGuid)?.includes(source?.sourceGuid)} onChange={(e) => handleCheckBoxChange(e.target.checked,source?.sourceGuid)}/>
                <label htmlFor={source?.sourceGuid}>
                  <span></span>{source?.sourceName}
                </label>
                </>
              ))}

            </div>
            {approvedResponse?.link && (
              <div className="content-link">
              <TextArea 
                name={"response-citation"}
                label="Citation"
                disabled={false}
                value = {citationText}
                onChange={onCitationChange}
                />
            </div>
            )}
            
            
         </div>
        <div className="edit-approved-response-container__footer">
            <button disabled={buttonDisabled} className="primary" onClick={(e) => onUpdate()}>Update</button>
            <button onClick={(e) => onClose(false)}>Close</button>
        </div>
    </div>
    </div>
    </>

  );
}

  export default EditApprovedResponse;
