import React, { useEffect, useRef, useState } from "react";
import { createDirectLine, createStore } from "botframework-webchat";
import { Components } from "botframework-webchat-component";
import Chat from "../Chat/Chat";
import Loader from "common/components/base/Loader/Loader";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./Chatbot.scss";
import PDFViewer from "../PDFViewer/PDFViewer";
import { IDocumentPage } from "common/models/chat/reference-document.model";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { userActions } from "common/redux/store/user";
import { chatActions } from "modules/gpo/redux/chat";
import Messages from "../Messages/Messages";
import { ChatDocumentPageModel } from "modules/gpo/models/chat/chat-coversation.model";

const ChatBot = () => {
  const [loader, setLoader] = useState(true);
  const [token, setToken] = useState("");
  
  const userState = useAppSelector((state: RootState) => state.user);
  const chatState = useAppSelector((state: RootState) => state.chat);
  const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
  const dispatch = useAppDispatch(); 

  // useEffect(() => {
  //   getToken();
  // }, []);
  useEffect(() => {
    dispatch(chatActions?.createCoversation());
  }, []);
  useEffect(()=>{
    if(chatState?.conversationGuid){
      console.log("chatState?.conversationGuid",chatState?.conversationGuid);
    }
  },[chatState?.conversationGuid])
  // useEffect(()=>{
  //   if(userState?.botToken){
  //     setToken(userState?.botToken);  
  //   }
  // },[userState?.botToken])

  // const store = createStore(
  //   {},
  //   ({ dispatch }: any) =>
  //     (next: any) =>
  //     (action: any) => {
  //       if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
  //         setLoader(false);
  //       }
  //       return next(action);
  //     }
  // );

  // const getToken = async () => {
  //   userStateDispatch(userActions?.getBotConversationToken());   
  // };

  const handleViewDoc = (pageNum: ChatDocumentPageModel[], documentGuid:string,activityId:string,showFullView:boolean,docProcessedDate:string) => {
    dispatch(chatActions?.updateChatScrollHeight(activityId));
    dispatch(chatActions?.updateChatDocGuidState(documentGuid));
    dispatch(chatActions?.updateChatDocPageNumState(pageNum));
    dispatch(chatActions?.updateDocOpenState(true));
    dispatch(chatActions?.updateChatDocDateState(docProcessedDate));
    if(showFullView){
      dispatch(chatActions?.updatePDFFullScreen(true));
    }
    else{
      dispatch(chatActions?.updatePDFFullScreen(false));
    }
  };

  return (
    <React.Fragment>
      {/* {loader && <Loader fullScreen />}
      {!!token && ( */}
        {/* <Components.Composer
          directLine={createDirectLine({ token })}
          userID="r_1675964726"
          store={store}
        > */}
          <div className="gpo-custom-chat-container">
          {/* <div className={chatHistoryState?.searchTriggered && chatHistoryState?.searchValue!="" ? "gpo-custom-chat-container message-list":"gpo-custom-chat-container"}> */}
            {/* {chatHistoryState?.searchTriggered && chatHistoryState?.searchValue!="" &&( */}
            <Messages/>
            {/* )} */}
            <div className="gpo-custom-chat-container__chat-wrapper">
            <div id="gpo-custom-chat-wrapper" >
                <Chat onViewDoc={handleViewDoc} />            
                <PDFViewer/>                
              </div>
            </div>
          </div>
        {/* </Components.Composer> */}
      {/* )} */}
    </React.Fragment>
  );
};

export default ChatBot;
